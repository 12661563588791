import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-62114f22")
const _hoisted_1 = {
  class: "z-30 h-full w-full top-0 sticky",
  id: "menuConatiner"
}
_popScopeId()

import { onMounted, onUnmounted, ref } from 'vue';

import AppIcon from '@/components/images/AppIcon.vue';
import AppLogo from '@/components/images/AppLogo.vue';
import useAlerts from '@/composables/useAlerts';
import useBreakpoints from '@/composables/useBreakpoints';
import useFathom from '@/composables/useFathom';

import AppNavActions from './AppNavActions.vue';
import AppNavAlert from './AppNavAlert.vue';
import DesktopLinks from './DesktopLinks/DesktopLinks.vue';
import useWeb3 from '@/services/web3/useWeb3';

/**
 * STATE
 */

export default _defineComponent({
  setup(__props) {

const appNav = ref<HTMLDivElement>();
const nvb = ref<HTMLDivElement>();

/**
 * COMPOSABLES
 */
const { bp, isDesktop, isMobile } = useBreakpoints();
const { trackGoal, Goals } = useFathom();
const { currentAlert } = useAlerts();

/**
 * METHODS
 */
function handleScroll() {
  if (!appNav.value) return;
  if (!nvb.value) return;

  if (window.scrollY === 0) {
    appNav.value.classList.remove('shadow-lg');
    nvb.value.classList.remove('navbar-bg');
  } else {
    appNav.value.classList.add('shadow-lg');
    nvb.value.classList.add('navbar-bg');
  }
}

/**
 * LIFECYCLE
 */
onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(currentAlert))
      ? (_openBlock(), _createBlock(AppNavAlert, {
          key: 0,
          alert: _unref(currentAlert)
        }, null, 8, ["alert"]))
      : _createCommentVNode("", true),
    _createElementVNode("nav", {
      id: "app-nav",
      ref: appNav,
      class: "h-20 pr-1 pl-4 lg:px-6 "
    }, [
      _createElementVNode("div", {
        ref: nvb,
        class: "flex h-full w-full items-center justify-between px-10"
      }, [
        _createVNode(_component_router_link, {
          to: { name: 'home' },
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(trackGoal)(_unref(Goals).ClickNavLogo)))
        }, {
          default: _withCtx(() => [
            (['xs', 'sm'].includes(_unref(bp)))
              ? (_openBlock(), _createBlock(AppIcon, { key: 0 }))
              : (_openBlock(), _createBlock(AppLogo, { key: 1 }))
          ]),
          _: 1
        }),
        (_unref(isDesktop))
          ? (_openBlock(), _createBlock(DesktopLinks, {
              key: 0,
              class: "flex font-medium"
            }))
          : _createCommentVNode("", true),
        _createVNode(AppNavActions, { class: "actions" })
      ], 512)
    ], 512)
  ]))
}
}

})