import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7ede40a5")
const _hoisted_1 = { class: "label text-secondary" }
const _hoisted_2 = ["type", "name", "value", "disabled"]
_popScopeId()

import { omit } from 'lodash';
import { computed, onMounted, ref, useAttrs, watch } from 'vue';

import { HtmlInputEvent } from '@/types';

import useInputEvents from './composables/useInputEvents';
import useInputStyles from './composables/useInputStyles';
import useInputValidation from './composables/useInputValidation';
import useWeb3 from '@/services/web3/useWeb3';

/**
 * TYPES
 */
export type InputValue = string | number;
export type InputType = 'text' | 'number' | 'date' | 'email' | 'password';
export type InputSize = 'xs' | 'sm' | 'md' | 'lg';
export type ValidationTrigger = 'input' | 'blur';
export type RuleFunction = (val: InputValue) => string;
export type Rules = RuleFunction[];

type Props = {
  name: string;
  modelValue: InputValue;
  isValid?: boolean;
  type?: InputType;
  size?: InputSize;
  disabled?: boolean;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  label?: string;
  inputAlignRight?: boolean;
  decimalLimit?: number;
  validateOn?: ValidationTrigger;
  rules?: Rules;
  noRadius?: boolean;
  noShadow?: boolean;
  noBorder?: boolean;
  autoFocus?: boolean;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  format?: (input: string | number) => string | number;
};

/**
 * PROPS & EMITS
 */

// https://v3.vuejs.org/api/sfc-script-setup.html#usage-alongside-normal-script
const __default__ = {
  inheritAttrs: false,
};

function setup(__props: {
  name: string;
  modelValue: InputValue;
  isValid?: boolean;
  type?: InputType;
  size?: InputSize;
  disabled?: boolean;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  label?: string;
  inputAlignRight?: boolean;
  decimalLimit?: number;
  validateOn?: ValidationTrigger;
  rules?: Rules;
  noRadius?: boolean;
  noShadow?: boolean;
  noBorder?: boolean;
  autoFocus?: boolean;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  format?: (input: string | number) => string | number;
}, { emit }: { emit: ({
  (e: 'blur', value: string): void;
  (e: 'input', value: string): void;
  (e: 'update:modelValue', value: string): void;
  (e: 'update:isValid', value: boolean): void;
  (e: 'keydown', value: HtmlInputEvent);
  (e: 'mouseOver', value: Event);
  (e: 'mouseLeave', value: Event);
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const textInput = ref<HTMLInputElement>();

/**
 * COMPOSABLES
 */
const attrs = useAttrs();
const { errors, isInvalid, validate } = useInputValidation(props, emit);
const {
  isActive,
  isHover,
  onInput,
  onKeydown,
  onBlur,
  onClick,
  onFocus,
  onMouseOver,
  onMouseLeave,
} = useInputEvents(props, emit, validate);
const {
  parentClasses,
  inputContainerClasses,
  inputGroupClasses,
  headerClasses,
  footerClasses,
  inputClasses,
  prependClasses,
  appendClasses,
  borderRadiusClasses,
} = useInputStyles(props, isInvalid, isActive, isHover, attrs);
const { isWalletReady } = useWeb3();

/**
 * COMPUTED
 */
// We don't want to pass on parent level classes to the html
// input element. So we need to remove it from the attrs object.
const inputAttrs = computed(() => omit(attrs, 'class'));

/**
 * LIFECYCLE
 */
onMounted(() => {
  textInput.value?.focus();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bal-text-input', _unref(parentClasses), _unref(borderRadiusClasses)])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['input-container', _unref(inputContainerClasses), _unref(borderRadiusClasses)]),
      onMouseover: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_unref(onMouseOver) && _unref(onMouseOver)(...args))),
      onMouseleave: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_unref(onMouseLeave) && _unref(onMouseLeave)(...args)))
    }, [
      (_ctx.$slots.header || __props.label)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(['header', _unref(headerClasses)])
          }, [
            _renderSlot(_ctx.$slots, "header", {}, () => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(__props.label), 1)
            ])
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["input-group flex-wrap mr-[6px]", {'mb-[22px]':!_unref(isWalletReady)}])
      }, [
        (_ctx.$slots.prepend)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['prepend', _unref(prependClasses)])
            }, [
              _renderSlot(_ctx.$slots, "prepend")
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("input", _mergeProps({
          ref: textInput,
          type: __props.type,
          name: __props.name,
          value: __props.modelValue
        }, _unref(inputAttrs), {
          disabled: __props.disabled,
          class: ['input flex-1', _unref(inputClasses)],
          onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onBlur) && _unref(onBlur)(...args))),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(onInput) && _unref(onInput)(...args))),
          onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(onKeydown) && _unref(onKeydown)(...args))),
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(onClick) && _unref(onClick)(...args))),
          onFocus: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_unref(onFocus) && _unref(onFocus)(...args)))
        }), null, 16, _hoisted_2),
        (_ctx.$slots.append)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(['append', _unref(appendClasses)])
            }, [
              _renderSlot(_ctx.$slots, "append")
            ], 2))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(['footer', _unref(footerClasses)])
          }, [
            _renderSlot(_ctx.$slots, "footer")
          ], 2))
        : _createCommentVNode("", true)
    ], 34)
  ], 2))
}
}


export default _defineComponent({
  ...__default__,
  props: {
    name: { type: String, required: true },
    modelValue: { type: [String, Number], required: true, default: '' },
    isValid: { type: Boolean, required: false, default: true },
    type: { type: String, required: false, default: 'text' },
    size: { type: String, required: false, default: 'md' },
    disabled: { type: Boolean, required: false, default: false },
    label: { type: String, required: false },
    inputAlignRight: { type: Boolean, required: false, default: false },
    decimalLimit: { type: Number, required: false, default: 18 },
    validateOn: { type: String, required: false, default: 'blur' },
    rules: { type: Array, required: false, default: () => [] },
    noRadius: { type: Boolean, required: false, default: false },
    noShadow: { type: Boolean, required: false, default: false },
    noBorder: { type: Boolean, required: false, default: false },
    autoFocus: { type: Boolean, required: false, default: false },
    format: { type: Function, required: false }
  } as unknown as undefined,
  emits: ["blur", "input", "update:modelValue", "update:isValid", "keydown", "mouseOver", "mouseLeave"] as unknown as undefined,
  setup})