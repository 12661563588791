
import { computed, defineComponent } from 'vue';

import Avatar from '@/components/images/Avatar.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useWeb3 from '@/services/web3/useWeb3';

export default defineComponent({
  name: 'AppNavAccountBtn',

  data() {
    return {
      showModal: false,
    };
  },

  components: {
    Avatar,
  },

  setup(props, { emit }) {
    const { bp, upToLargeBreakpoint, isMobile } = useBreakpoints();
    const { isLoadingProfile, profile, account } = useWeb3();

    const avatarSize = computed(() => {
      if (bp.value === 'sm') {
        return 35;
      } else if (['md', 'lg'].includes(bp.value)) {
        return 40;
      } else {
        return 20;
      }
    });

    const toggleMobileWallet = () => {
      emit('ToggleMobileWallet');
    };

    return {
      // computed
      bp,
      account,
      profile,
      avatarSize,
      upToLargeBreakpoint,
      isLoadingProfile,
      isMobile,
      Avatar,
      toggleMobileWallet,
    };
  },
});
