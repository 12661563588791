<template>
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6793 0.294665C13.5867 0.201296 13.4765 0.127186 13.3551 0.076612C13.2337 0.0260378 13.1035 0 12.972 0C12.8405 0 12.7103 0.0260378 12.5889 0.076612C12.4675 0.127186 12.3573 0.201296 12.2647 0.294665L4.84329 7.72608L1.72529 4.59811C1.62913 4.50523 1.51563 4.4322 1.39125 4.38318C1.26688 4.33417 1.13406 4.31013 1.0004 4.31244C0.86673 4.31475 0.734828 4.34337 0.612221 4.39666C0.489614 4.44995 0.378704 4.52686 0.285823 4.62301C0.192942 4.71917 0.119909 4.83267 0.0708932 4.95705C0.0218778 5.08142 -0.00216024 5.21424 0.000152332 5.3479C0.0024649 5.48157 0.0310826 5.61347 0.0843711 5.73608C0.13766 5.85869 0.214575 5.9696 0.310727 6.06248L4.13601 9.88776C4.22862 9.98113 4.3388 10.0552 4.46019 10.1058C4.58158 10.1564 4.71179 10.1824 4.84329 10.1824C4.9748 10.1824 5.105 10.1564 5.22639 10.1058C5.34779 10.0552 5.45796 9.98113 5.55057 9.88776L13.6793 1.75903C13.7804 1.66575 13.8611 1.55253 13.9163 1.42652C13.9715 1.3005 14 1.16442 14 1.02685C14 0.889276 13.9715 0.753194 13.9163 0.627179C13.8611 0.501165 13.7804 0.387948 13.6793 0.294665Z"
    />
  </svg>
</template>
