import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, unref as _unref, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7dd0aaec")
const _hoisted_1 = {
  key: 0,
  class: "app-sidebar pt-[60px]"
}
_popScopeId()

import { ref } from 'vue';

import { useSidebar } from '@/composables/useSidebar';

import SidebarContent from './SidebarContent.vue';

/**
 * STATE
 */

export default _defineComponent({
  emits: ['close', 'ToggleMobileWallet'],
  setup(__props, { emit }) {

const showSidebar = ref(false);


/**
 * COMPOSABLES
 */
const { setSidebarOpen } = useSidebar();

const toggleMobileWallet = () => {
  emit('ToggleMobileWallet');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    name: "overlay",
    appear: "",
    onAfterEnter: _cache[3] || (_cache[3] = ($event: any) => (showSidebar.value = true))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "sidebar-overlay",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (showSidebar.value = false))
      }, [
        _createVNode(_Transition, {
          name: "sidebar",
          onAfterLeave: _cache[1] || (_cache[1] = ($event: any) => (_unref(setSidebarOpen)(false)))
        }, {
          default: _withCtx(() => [
            (showSidebar.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(SidebarContent, {
                    onClose: _cache[0] || (_cache[0] = ($event: any) => (showSidebar.value = false)),
                    onToggleMobileWallet: toggleMobileWallet
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})