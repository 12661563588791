import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-085fcc7d")
const _hoisted_1 = {
  class: "flex items-center justify-between",
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "text-base text-gray-700 dark:text-white" }
const _hoisted_3 = { class: "capitalize" }
const _hoisted_4 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "wallet-connect-btn",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h5", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.WalletNameMap[_ctx.wallet]), 1)
      ]),
      _createElementVNode("img", {
        src: require(`@/assets/images/connectors/${_ctx.wallet}.svg`),
        class: "h-[24px] w-[24px]"
      }, null, 8, _hoisted_4)
    ])
  ]))
}