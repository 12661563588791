import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2d515978")
const _hoisted_1 = { class: "flex w-full switcher" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createBlock(_component_BalBtn, _mergeProps({
        key: option.value,
        size: "sm",
        class: "w-18 capitalize flex-1"
      }, _ctx.attrs_, {
        color: _ctx.modelValue === option.value ? 'gray' : 'transparent',
        onClick: ($event: any) => (_ctx.onSelect(option))
      }), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString('best' === option.label ? _ctx.$t(option.label) : option.label), 1)
        ]),
        _: 2
      }, 1040, ["color", "onClick"]))
    }), 128))
  ]))
}