import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-fe7ca0e4")
const _hoisted_1 = ["loading", "loadingLabel", "size", "circle"]
const _hoisted_2 = { class: "address" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMobileWallet && _ctx.toggleMobileWallet(...args))),
    class: _normalizeClass(["btn w-full text-center", { btn: _ctx.upToLargeBreakpoint }]),
    loading: _ctx.isLoadingProfile,
    loadingLabel: _ctx.upToLargeBreakpoint ? '' : _ctx.$t('connecting'),
    size: _ctx.upToLargeBreakpoint ? 'md' : 'sm',
    circle: _ctx.upToLargeBreakpoint
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx._shorten(_ctx.account)), 1)
  ], 10, _hoisted_1))
}