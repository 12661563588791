import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { compact } from 'lodash';
import { Ref } from 'vue';

import { ColumnDefinition, Data, Sticky } from './BalTable.vue';

type Props = {
  columns: ColumnDefinition<any>[];
  onRowClick?: (data: any) => void;
  data: Ref<any>;
  link?: any;
  sticky?: Sticky;
  isColumnStuck?: boolean;
  pinned?: boolean;
};


export default _defineComponent({
  props: {
    columns: { type: Array, required: true },
    onRowClick: { type: Function, required: false },
    data: { type: null, required: true },
    link: { type: null, required: false },
    sticky: { type: null, required: false },
    isColumnStuck: { type: Boolean, required: false },
    pinned: { type: Boolean, required: false }
  } as unknown as undefined,
  setup(__props: {
  columns: ColumnDefinition<any>[];
  onRowClick?: (data: any) => void;
  data: Ref<any>;
  link?: any;
  sticky?: Sticky;
  isColumnStuck?: boolean;
  pinned?: boolean;
}) {

const props = __props


function handleRowClick(data: Data) {
  if (props.link?.to) return;
  props.onRowClick && props.onRowClick(data);
}

// Need a method for horizontal stickiness as we need to
// check whether the table item belongs in the first column
function getHorizontalStickyClass(index: number) {
  if (index !== 0) return '';
  if (props.sticky === 'horizontal' || props.sticky === 'both') {
    return 'horizontalSticky';
  }
  return '';
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass([
      'row-bg group z-10 bg-white bg-[#231928]',
      {
        'cursor-pointer': handleRowClick,
        'border-b dark:border-gray-700': __props.pinned,
      },
    ]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (handleRowClick(__props.data)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.columns, (column, columnIndex) => {
      return (_openBlock(), _createElementBlock("td", {
        key: column.id,
        class: _normalizeClass([
        column.align === 'right' ? 'text-left' : 'text-right',
        getHorizontalStickyClass(columnIndex),
        __props.isColumnStuck ? 'isSticky' : '',
      ])
      }, [
        (__props.link)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              class: "bg-[#231928]",
              to: {
          name: __props.link.to,
          params: __props.link.getParams(__props.data),
        }
            }, {
              default: _withCtx(() => [
                (column.Cell)
                  ? _renderSlot(_ctx.$slots, column.Cell, _normalizeProps(_mergeProps({ key: 0 }, __props.data)))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(
            _unref(compact)([
              'bg-[#231928] px-6 py-4',
              column.align === 'right' ? 'text-right' : 'text-left',
              column.cellClassName,
            ])
          )
                    }, _toDisplayString(typeof column.accessor === 'string'
              ? __props.data[column.accessor]
              : column.accessor(__props.data)), 3))
              ]),
              _: 2
            }, 1032, ["to"]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (column.Cell)
                ? _renderSlot(_ctx.$slots, column.Cell, _normalizeProps(_mergeProps({ key: 0 }, __props.data)))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(
            _unref(compact)([
              'bg-[#231928] px-6 py-4',
              column.align === 'right' ? 'text-right' : 'text-left',
              column.cellClassName,
            ])
          )
                  }, _toDisplayString(typeof column.accessor === 'string'
              ? __props.data[column.accessor]
              : column.accessor(__props.data)), 3))
            ], 64))
      ], 2))
    }), 128))
  ], 2))
}
}

})