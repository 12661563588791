import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-secondary mb-2" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "text-sm font-semibold" }
const _hoisted_4 = { class: "text-secondary" }

import { computed } from 'vue';

import BalAsset from '@/components/_global/BalAsset/BalAsset.vue';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { shortenLabel } from '@/lib/utils';
import { PoolToken } from '@/services/pool/types';
import useWeb3 from '@/services/web3/useWeb3';

/**
 * TYPES
 */
type Props = {
  token: PoolToken;
  symbol: string;
};


export default _defineComponent({
  props: {
    token: { type: null, required: true },
    symbol: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  token: PoolToken;
  symbol: string;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum2, toFiat } = useNumbers();
const { account } = useWeb3();
const { balanceFor } = useTokens();

/**
 * COMPUTED
 */
const tokenBalance = computed(() => balanceFor(props.token.address));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('tokenPills.balanceTooltip.title', [_unref(shortenLabel)(_unref(account))])), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(BalAsset, {
        address: __props.token.address,
        size: 36,
        class: "mr-2"
      }, null, 8, ["address"]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(fNum2)(_unref(tokenBalance), _unref(FNumFormats).token)) + " " + _toDisplayString(__props.symbol), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(fNum2)(_unref(toFiat)(_unref(tokenBalance), __props.token.address), _unref(FNumFormats).fiat)), 1)
      ])
    ])
  ]))
}
}

})