import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

import { computed } from 'vue';

import useDarkMode from '@/composables/useDarkMode';

/**
 * TYPES
 */
type RoundedOpts = 'sm' | 'md' | 'lg';

type Props = {
  white?: boolean;
  darker?: boolean;
  square?: boolean;
  rounded?: RoundedOpts;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    white: { type: Boolean, required: false, default: false },
    darker: { type: Boolean, required: false, default: false },
    square: { type: Boolean, required: false, default: false },
    rounded: { type: String, required: false, default: 'lg' }
  } as unknown as undefined,
  setup(__props: {
  white?: boolean;
  darker?: boolean;
  square?: boolean;
  rounded?: RoundedOpts;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { darkMode } = useDarkMode();

/**
 * COMPUTED
 */
const bgClass = computed(() => {
  if (props.white) return 'shimmer-white';
  if (darkMode.value) {
    return props.darker ? 'shimmer-dark-mode-darker' : 'shimmer-dark-mode';
  }
  return props.darker ? 'shimmer-light-mode-darker' : 'shimmer-light-mode';
});

const classes = computed(() => {
  return {
    [`rounded-${props.rounded}`]: !props.square,
    [bgClass.value]: true,
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bal-loading-block', _unref(classes)])
  }, null, 2))
}
}

})