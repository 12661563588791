import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2c9d43fb")
const _hoisted_1 = ["textContent"]
_popScopeId()

import { createPopper, Instance as PopperInstance } from '@popperjs/core';
import { computed, onMounted, onUnmounted, ref } from 'vue';

import BalIcon, { IconSize } from '../BalIcon/BalIcon.vue';

type Placement = 'top' | 'left' | 'bottom' | 'right';
type TextAlign = 'left' | 'center' | 'right' | '';

type Props = {
  text?: string;
  placement?: Placement;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  onShow?: () => void;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  onHide?: () => void;
  noPad?: boolean;
  disabled?: boolean;
  iconSize?: IconSize;
  iconName?: string;
  iconClass?: string;
  width?: string;
  textAlign?: TextAlign;
  delayMs?: number;
};


export default _defineComponent({
  props: {
    text: { type: String, required: false, default: '' },
    placement: { type: String, required: false, default: 'top' },
    onShow: { type: Function, required: false },
    onHide: { type: Function, required: false },
    noPad: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    iconSize: { type: null, required: false, default: 'md' },
    iconName: { type: String, required: false, default: 'info' },
    iconClass: { type: String, required: false, default: 'text-gray-300' },
    width: { type: String, required: false, default: '52' },
    textAlign: { type: String, required: false, default: '' },
    delayMs: { type: Number, required: false, default: 0 }
  } as unknown as undefined,
  setup(__props: {
  text?: string;
  placement?: Placement;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  onShow?: () => void;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  onHide?: () => void;
  noPad?: boolean;
  disabled?: boolean;
  iconSize?: IconSize;
  iconName?: string;
  iconClass?: string;
  width?: string;
  textAlign?: TextAlign;
  delayMs?: number;
}) {

const props = __props


const activator = ref<HTMLElement>();
const content = ref<HTMLElement>();
const popper = ref<PopperInstance>();

let delayTimeout: ReturnType<typeof setTimeout>;

const tooltipClasses = computed(() => {
  return {
    [`w-${props.width}`]: true,
    [`text-${props.textAlign}`]: props.textAlign !== '',
  };
});

const tooltipPad = computed(() => {
  return {
    'p-3': !props.noPad,
  };
});

// show the tooltip
function showTooltip() {
  if (content.value && popper.value) {
    content.value.setAttribute('data-show', '');
    popper.value.update();
    props.onShow && props.onShow();
  }
}

// hide the tooltip
function hideTooltip() {
  if (content.value) {
    content.value.removeAttribute('data-show');
    props.onHide && props.onHide();
  }
}

function handleMouseEnter() {
  if (!props.disabled) {
    if (props.delayMs > 0) {
      delayTimeout = setTimeout(showTooltip, props.delayMs);
    } else {
      showTooltip();
    }
  }
}

function handleMouseLeave() {
  if (!props.disabled) {
    if (delayTimeout != null) {
      clearTimeout(delayTimeout);
    }
    hideTooltip();
  }
}

onMounted(() => {
  if (activator.value && content.value) {
    popper.value = createPopper(activator.value, content.value, {
      placement: props.placement,
      modifiers: [
        { name: 'offset', options: { offset: [0, 8] } },
        { name: 'eventListeners', options: { scroll: false } },
      ],
    });
  }
});

onUnmounted(() => {
  if (delayTimeout != null) {
    clearTimeout(delayTimeout);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", _mergeProps({
      ref: activator,
      class: ['leading-none', { 'cursor-default': __props.disabled }]
    }, _ctx.$attrs, {
      onMouseenter: handleMouseEnter,
      onMouseleave: handleMouseLeave
    }), [
      _renderSlot(_ctx.$slots, "activator", {}, () => [
        _createVNode(BalIcon, {
          name: __props.iconName,
          size: __props.iconSize,
          class: _normalizeClass(__props.iconClass)
        }, null, 8, ["name", "size", "class"])
      ])
    ], 16),
    _createElementVNode("div", {
      ref: content,
      class: _normalizeClass(["tooltip", _unref(tooltipClasses)])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([_unref(tooltipPad), "tooltip-content font-medium"])
      }, [
        (__props.text)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "tooltip-text",
              textContent: _toDisplayString(__props.text)
            }, null, 8, _hoisted_1))
          : _renderSlot(_ctx.$slots, "default", { key: 1 })
      ], 2)
    ], 2)
  ], 64))
}
}

})