import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

import { computed } from 'vue';

type Props = {
  size?: string;
  color?: string;
};


export default _defineComponent({
  props: {
    size: { type: String, required: false, default: '16' },
    color: { type: String, required: false, default: 'gray' }
  } as unknown as undefined,
  setup(__props: {
  size?: string;
  color?: string;
}) {

const props = __props


const classes = computed(() => ({
  [`w-${props.size} h-${props.size}`]: true,
  [`bg-${props.color}-500`]: true,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bal-circle', _unref(classes)])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})