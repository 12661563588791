import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createTextVNode as _createTextVNode, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3c3034e4")
const _hoisted_1 = {
  key: 0,
  class: "mb-[24px]"
}
const _hoisted_2 = { class: "w-full table-fixed whitespace-normal bg-[#231928]" }
const _hoisted_3 = { class: "z-10" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "text-base" }
const _hoisted_7 = {
  key: 1,
  class: "row-bg text-secondary flex h-40 max-w-full items-center justify-center bg-white dark:bg-gray-850"
}
const _hoisted_8 = {
  key: 2,
  class: "w-full table-fixed whitespace-normal"
}
_popScopeId()

import BalIcon from '../BalIcon/BalIcon.vue';
import BalTableRow from './BalTableRow.vue';
import TotalsRow from './TotalsRow.vue';
export type Sticky = 'horizontal' | 'vertical' | 'both';
export type Data = any;
export type ColumnDefinition<T = Data> = {
  // Column Header Label
  name: string;
  // Unique ID
  id: string;
  // Key or function to access data from the row
  accessor: string | ((row: T) => string);
  // Slot ID for custom rendering the cell
  Cell?: string;
  // Slot ID for custom rendering a header
  Header?: string;
  // Is the column sortable
  isSortable?: boolean;
  // Extra classes to supply to the column. E.g. min width
  className?: string;
  // Left or right aligned content. E.g. Numbers should be right aligned
  align?: 'left' | 'right' | 'center';
  // Should the column width grow to fit available space?
  noGrow?: boolean;
  // Set to true to hide the column
  hidden?: boolean;
  // Accessor for sorting purposes
  sortKey?: string | ((row: T) => unknown);

  width?: number;

  totalsCell?: string;

  cellClassName?: string;
};
import { sortBy, sumBy } from 'lodash';
import { computed, onMounted, ref, watch } from 'vue';

import PinHeader from './PinHeader.vue';

type InitialState = {
  sortDirection: 'asc' | 'desc' | null;
  sortColumn: string | null;
};

type DataPinState = {
  // the key inside each data object that you want to
  // pin by
  pinOn: string;
  pinnedData: string[];
};

type Props = {
  columns: ColumnDefinition[];
  data: Data[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
  skeletonClass?: string;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  onRowClick?: (data: Data) => void;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  sticky?: Sticky;
  square?: boolean;
  isPaginated?: boolean;
  noResultsLabel?: string;
  link?: { to?: string } | null;
  initialState?: InitialState;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  pin?: DataPinState | null;
  getTableRowClass?: (rowData: Data, rowIndex: number) => string | undefined;
  title: String;
};


export default _defineComponent({
  props: {
    columns: { type: Array, required: true },
    data: { type: Array, required: true },
    isLoading: { type: Boolean, required: false, default: false },
    isLoadingMore: { type: Boolean, required: false, default: false },
    skeletonClass: { type: String, required: false, default: '' },
    onRowClick: { type: Function, required: false },
    sticky: { type: String, required: false },
    square: { type: Boolean, required: false, default: false },
    isPaginated: { type: Boolean, required: false, default: false },
    noResultsLabel: { type: String, required: false, default: '' },
    link: { type: [Object, null], required: false, default: () => null },
    initialState: { type: Object, required: false, default: () => ({
    sortColumn: null,
    sortDirection: null,
  }) },
    pin: { type: [Object, null], required: false },
    getTableRowClass: { type: Function, required: false, default: () => undefined },
    title: { type: null, required: true }
  } as unknown as undefined,
  emits: ['loadMore'],
  setup(__props: {
  columns: ColumnDefinition[];
  data: Data[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
  skeletonClass?: string;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  onRowClick?: (data: Data) => void;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  sticky?: Sticky;
  square?: boolean;
  isPaginated?: boolean;
  noResultsLabel?: string;
  link?: { to?: string } | null;
  initialState?: InitialState;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  pin?: DataPinState | null;
  getTableRowClass?: (rowData: Data, rowIndex: number) => string | undefined;
  title: String;
}) {

const props = __props




const stickyHeaderRef = ref();
const isColumnStuck = ref(false);
const tableData = ref(props.data);
const currentSortDirection = ref<InitialState['sortDirection']>(
  props.initialState?.sortDirection || null
);
const currentSortColumn = ref<InitialState['sortColumn']>(
  props.initialState?.sortColumn || null
);
const headerRef = ref<HTMLElement>();
const bodyRef = ref<HTMLElement>();

// for loading and no results
const placeholderBlockWidth = computed(() => sumBy(props.columns, 'width'));

const setHeaderRef = (columnIndex: number) => (el: HTMLElement) => {
  if (el && columnIndex === 0) {
    stickyHeaderRef.value = el;
  }
};

// Need a method for horizontal stickiness as we need to
// check whether the table item belongs in the first column
const getHorizontalStickyClass = (index: number) => {
  if (index !== 0) return '';
  if (props.sticky === 'horizontal' || props.sticky === 'both') {
    return 'horizontalSticky';
  }
  return '';
};

const handleSort = (columnId: string | null, updateDirection = true) => {
  const column = props.columns.find(column => column.id === columnId);
  if (!column?.sortKey) return;
  if (columnId !== currentSortColumn.value) currentSortDirection.value = null;

  currentSortColumn.value = columnId;

  if (updateDirection) {
    if (currentSortDirection.value === null) {
      currentSortDirection.value = 'desc';
    } else if (currentSortDirection.value === 'desc') {
      currentSortDirection.value = 'asc';
    } else {
      currentSortDirection.value = null;
    }
  }

  const sortedData = sortBy(
    (props.data as any).value || props.data,
    column.sortKey
  );
  if (currentSortDirection.value === 'asc') {
    tableData.value = sortedData;
    return;
  } else if (currentSortDirection.value === 'desc') {
    tableData.value = sortedData.reverse();
    return;
  }
  tableData.value = props.data;
};

function getAlignProperty(align: 'left' | 'right' | 'center' | undefined) {
  switch (align) {
    case 'left':
      return 'justify-start';
    case 'right':
      return 'justify-end';
    case 'center':
      return 'justify-center';
    default:
      return 'justify-start';
  }
}

onMounted(() => {
  if (bodyRef.value) {
    bodyRef.value.onscroll = () => {
      if (bodyRef.value) {
        const offsetRatio =
          bodyRef.value.offsetWidth / stickyHeaderRef.value.offsetWidth / 10;
        isColumnStuck.value = !!(
          stickyHeaderRef.value.offsetLeft >
          stickyHeaderRef.value.offsetWidth * offsetRatio
        );
      }
    };
    bodyRef.value.addEventListener('scroll', () => {
      if (bodyRef.value && headerRef.value) {
        headerRef.value.scrollLeft = bodyRef.value.scrollLeft;
      }
    });
  }

  handleSort(currentSortColumn.value, false);
});

/**
 * COMPUTED
 */
const unpinnedData = computed(() => {
  if (!props.pin) return tableData.value;
  return (tableData.value || []).filter(
    data => !props.pin?.pinnedData.includes(data[props.pin.pinOn])
  );
});

const pinnedData = computed(() => {
  if (!props.pin) return [];
  return (tableData.value || []).filter(data =>
    props.pin?.pinnedData.includes(data[props.pin.pinOn])
  );
});

const filteredColumns = computed(() =>
  props.columns.filter(column => !column.hidden)
);

const shouldRenderTotals = computed(() =>
  props.columns.some(column => column.totalsCell !== undefined)
);

watch(
  () => props.data,
  newData => {
    if (currentSortColumn.value && currentSortDirection.value !== null) {
      handleSort(currentSortColumn.value, false);
      return;
    }
    tableData.value = newData;
  }
);

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([
      'max-w-full overflow-hidden whitespace-nowrap ',
      { 'rounded-lg': !__props.square },
    ])
    }, [
      (__props.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(__props.title), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        ref: headerRef,
        class: "overflow-hidden"
      }, [
        _createElementVNode("table", _hoisted_2, [
          _createElementVNode("colgroup", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredColumns), (column) => {
              return (_openBlock(), _createElementBlock("col", {
                key: column.id,
                style: _normalizeStyle({ width: `${column?.width}px` })
              }, null, 4))
            }), 128))
          ]),
          _createElementVNode("thead", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredColumns), (column, columnIndex) => {
              return (_openBlock(), _createElementBlock("th", {
                key: `header-${column.id}`,
                ref: setHeaderRef(columnIndex),
                class: _normalizeClass([
              'headingShadow bg-[#231928]',
              column.className,
              getHorizontalStickyClass(columnIndex),
              isColumnStuck.value ? 'isSticky' : '',
              column.sortKey ? 'cursor-pointer' : '',
              currentSortColumn.value === column.id && currentSortDirection.value,
            ]),
                onClick: ($event: any) => (handleSort(column.id))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(['flex bg-[#231928]', getAlignProperty(column.align)])
                }, [
                  (column.Header)
                    ? _renderSlot(_ctx.$slots, column.Header, _normalizeProps(_mergeProps({ key: 0 }, column)))
                    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("h5", _hoisted_6, _toDisplayString(column.name), 1)
                      ])),
                  (
                  currentSortColumn.value === column.id &&
                  currentSortDirection.value === 'asc'
                )
                    ? (_openBlock(), _createBlock(BalIcon, {
                        key: 2,
                        name: "arrow-up",
                        size: "sm",
                        class: "ml-1 flex items-center"
                      }))
                    : _createCommentVNode("", true),
                  (
                  currentSortColumn.value === column.id &&
                  currentSortDirection.value === 'desc'
                )
                    ? (_openBlock(), _createBlock(BalIcon, {
                        key: 3,
                        name: "arrow-down",
                        size: "sm",
                        class: "ml-1 flex items-center"
                      }))
                    : _createCommentVNode("", true)
                ], 2)
              ], 10, _hoisted_4))
            }), 128))
          ])
        ])
      ], 512),
      _createElementVNode("div", {
        ref: bodyRef,
        class: "overflow-auto"
      }, [
        (__props.isLoading)
          ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
              key: 0,
              class: _normalizeClass([__props.skeletonClass, 'min-w-full']),
              square: "",
              style: _normalizeStyle({ width: `${_unref(placeholderBlockWidth)}px` })
            }, null, 8, ["class", "style"]))
          : (!__props.isLoading && !tableData.value.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(__props.noResultsLabel || _ctx.$t('noResults')), 1))
            : (_openBlock(), _createElementBlock("table", _hoisted_8, [
                _createElementVNode("colgroup", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredColumns), (column) => {
                    return (_openBlock(), _createElementBlock("col", {
                      key: column.id,
                      style: _normalizeStyle({ width: `${column?.width}px` })
                    }, null, 4))
                  }), 128))
                ]),
                _createElementVNode("tr", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredColumns), (column, columnIndex) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: column.id,
                      class: _normalizeClass([
              column.align === 'right' ? 'text-left' : 'text-right',
              getHorizontalStickyClass(columnIndex),
              isColumnStuck.value ? 'isSticky' : '',
              'm-0 h-0 bg-white p-0 dark:bg-gray-850',
            ])
                    }, null, 2))
                  }), 128))
                ]),
                (_unref(pinnedData).length)
                  ? (_openBlock(), _createBlock(PinHeader, { key: 0 }))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pinnedData), (dataItem, index) => {
                  return (_openBlock(), _createBlock(BalTableRow, {
                    key: `tableRow-${index}`,
                    class: _normalizeClass(props.getTableRowClass(dataItem, index)),
                    data: dataItem,
                    columns: _unref(filteredColumns),
                    onRowClick: __props.onRowClick,
                    link: __props.link,
                    sticky: __props.sticky,
                    isColumnStuck: isColumnStuck.value,
                    pinned: ""
                  }, _createSlots({ _: 2 }, [
                    _renderList(_ctx.$slots, (_, name) => {
                      return {
                        name: name,
                        fn: _withCtx((slotData) => [
                          _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(slotData)))
                        ])
                      }
                    })
                  ]), 1032, ["class", "data", "columns", "onRowClick", "link", "sticky", "isColumnStuck"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(unpinnedData), (dataItem, index) => {
                  return (_openBlock(), _createBlock(BalTableRow, {
                    key: `tableRow-${index}`,
                    class: _normalizeClass(props.getTableRowClass(dataItem, index)),
                    data: dataItem,
                    columns: _unref(filteredColumns),
                    onRowClick: __props.onRowClick,
                    link: __props.link,
                    sticky: __props.sticky,
                    isColumnStuck: isColumnStuck.value
                  }, _createSlots({ _: 2 }, [
                    _renderList(_ctx.$slots, (_, name) => {
                      return {
                        name: name,
                        fn: _withCtx((slotData) => [
                          _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(slotData)))
                        ])
                      }
                    })
                  ]), 1032, ["class", "data", "columns", "onRowClick", "link", "sticky", "isColumnStuck"]))
                }), 128)),
                (!__props.isLoading && tableData.value.length && _unref(shouldRenderTotals))
                  ? (_openBlock(), _createBlock(TotalsRow, {
                      key: 1,
                      columns: _unref(filteredColumns),
                      onRowClick: __props.onRowClick,
                      sticky: __props.sticky,
                      isColumnStuck: isColumnStuck.value
                    }, _createSlots({ _: 2 }, [
                      _renderList(_ctx.$slots, (_, name) => {
                        return {
                          name: name,
                          fn: _withCtx((slotData) => [
                            _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(slotData)))
                          ])
                        }
                      })
                    ]), 1032, ["columns", "onRowClick", "sticky", "isColumnStuck"]))
                  : _createCommentVNode("", true)
              ]))
      ], 512)
    ], 2),
    (__props.isPaginated && !__props.isLoading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "bal-table-pagination-btn text-secondary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (!__props.isLoadingMore && _ctx.$emit('loadMore')))
        }, [
          (__props.isLoadingMore)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('loading')), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('loadMore')) + " ", 1),
                _createVNode(BalIcon, {
                  name: "chevron-down",
                  size: "sm",
                  class: "ml-2"
                })
              ], 64))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})