<template>
    <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <g clip-path="url(#clip0_699_6305)">
            <path
            d="M16.6665 28C23.2939 28 28.6665 22.6274 28.6665 16C28.6665 9.37258 23.2939 4 16.6665 4C10.0391 4 4.6665 9.37258 4.6665 16C4.6665 22.6274 10.0391 28 16.6665 28Z"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M20.3998 12.0004C20.1583 11.5814 19.8074 11.236 19.3846 11.0011C18.9619 10.7662 18.4832 10.6508 17.9998 10.667H15.3332C14.6259 10.667 13.9477 10.948 13.4476 11.4481C12.9475 11.9482 12.6665 12.6265 12.6665 13.3337C12.6665 14.0409 12.9475 14.7192 13.4476 15.2193C13.9477 15.7194 14.6259 16.0004 15.3332 16.0004H17.9998C18.7071 16.0004 19.3854 16.2813 19.8855 16.7814C20.3856 17.2815 20.6665 17.9598 20.6665 18.667C20.6665 19.3743 20.3856 20.0526 19.8855 20.5527C19.3854 21.0528 18.7071 21.3337 17.9998 21.3337H15.3332C14.8498 21.35 14.3712 21.2345 13.9484 20.9996C13.5256 20.7648 13.1747 20.4194 12.9332 20.0004"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M16.6665 8V10.6667M16.6665 21.3333V24"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_699_6305">
            <rect
                width="32"
                height="32"
                fill="white"
                transform="translate(0.666504)"
            />
            </clipPath>
        </defs>
        </svg>
</template>