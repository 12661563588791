import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-325aac9b")
const _hoisted_1 = { class: "fade-in-delay w-full opacity-0" }
const _hoisted_2 = { class: "link-container" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "link-container mt-[66px]" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "px-[24px] py-[44px]"
}
const _hoisted_8 = { key: 0 }
_popScopeId()

import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import AppLogo from '@/components/images/AppLogo.vue';
import useApp from '@/composables/useApp';
import useConfig from '@/composables/useConfig';
import useDarkMode from '@/composables/useDarkMode';
import { sleep } from '@/lib/utils';
import useWeb3 from '@/services/web3/useWeb3';

import AppNavAccountBtn from '../AppNavAccountBtn.vue';
import MobileWalletBtn from './MobileWalletBtn.vue';

import useBreakpoints from '@/composables/useBreakpoints';

import swapImg from './swap.svg';
import investImg from './invest.svg';
import portfolioImg from './portfolio.svg';
import vexpolarImg from './vexPolar.svg';
import aboutImg from './about.svg';
import helpImg from './help.svg';
import discordImg from './discord.svg';
import docsImg from './docs.svg';
import legalImg from './legal.svg';
import kycImg from './kyc.svg';

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  emits: ['close', 'ToggleMobileWallet'],
  setup(__props, { emit }) {



/**
 * COMPOSABLES
 */
const { darkMode, toggleDarkMode } = useDarkMode();
const { blockNumber } = useWeb3();
const { networkConfig } = useConfig();
const { version } = useApp();
const { t } = useI18n();
const router = useRouter();
const { account, connector, startConnectWithInjectedProvider } = useWeb3();
const { isMobile, isDesktop, upToSmallBreakpoint } = useBreakpoints();
console.log(upToSmallBreakpoint);

/**
 * STATE
 */
const blockIcon = ref<HTMLDivElement>();

const navLinks = [
  { label: t('swap'), path: '/trade', img: swapImg },
  { label: 'Earn', path: '/', img: investImg },
  { label: 'Sunrise', path: '/sunrise', img: investImg },
  { label: 'Bond', path: '/bond', img: investImg },
  // { label: t('portfolio'), path: '/vebal', img: portfolioImg },
  { label: 'Airdrop', path: '/airdrop', img: portfolioImg },
  { label: 'vexPOLAR', path: '/vexpolar', img: vexpolarImg },
  // { label: t('claim'), path: '/claim' },
];

const ecosystemLinks = [
  { label: t('About'), url: 'https://home.polarisfinance.io', img: aboutImg },
  // {
  //   label: t('Help Center'),
  //   url: 'https://medium.com/balancer-protocol',
  //   img: helpImg,
  // },
  {
    label: t('Discord'),
    url: 'https://discord.gg/gFEaCXACF4',
    img: discordImg,
  },
  { label: t('Docs'), url: 'https://docs.polarisfinance.io/', img: docsImg },
  // {
  //   label: t('Legal & Privacy'),
  //   url: 'https://dune.xyz/balancerlabs',
  //   img: legalImg,
  // },
  {
    label: t('KYC'),
    url: 'https://www.apeoclock.com/launch/polaris-finance-genesis-pools-launch/',
    img: kycImg,
  },
  // {
  //   label: t('grants'),
  //   url: 'http://grants.balancer.community',
  // },
];

const socialLinks = [
  // { component: 'TwitterIcon', url: 'https://twitter.com/BalancerLabs' },
  // { component: 'DiscordIcon', url: 'https://discord.balancer.fi/' },
  // { component: 'MediumIcon', url: 'https://medium.com/balancer-protocol' },
  // {
  //   component: 'YoutubeIcon',
  //   url: 'https://www.youtube.com/channel/UCBRHug6Hu3nmbxwVMt8x_Ow',
  // },
  // { component: 'GithubIcon', url: 'https://github.com/balancer-labs/' },
];

/**
 * METHODS
 */
async function navTo(path: string) {
  router.push(path);
  emit('close');
}

/**
 * WATCHERS
 */
watch(blockNumber, async () => {
  blockIcon.value?.classList.add('block-change');
  await sleep(300);
  blockIcon.value?.classList.remove('block-change');
});

const toggleMobileWallet = () => {
  emit('ToggleMobileWallet');
};

return (_ctx: any,_cache: any) => {
  const _component_BalLink = _resolveComponent("BalLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(navLinks, (link) => {
          return _createElementVNode("div", {
            key: link.label,
            class: "side-bar-link flex justify-between",
            onClick: ($event: any) => (navTo(link.path))
          }, [
            _createElementVNode("div", null, _toDisplayString(link.label), 1),
            _createElementVNode("img", {
              src: link.img
            }, null, 8, _hoisted_4)
          ], 8, _hoisted_3)
        }), 64))
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(ecosystemLinks, (link) => {
          return _createVNode(_component_BalLink, {
            key: link.url,
            href: link.url,
            class: "side-bar-link flex items-center justify-between",
            external: "",
            noStyle: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(link.label), 1),
              _createElementVNode("img", {
                src: link.img
              }, null, 8, _hoisted_6)
            ]),
            _: 2
          }, 1032, ["href"])
        }), 64))
      ])
    ]),
    (_unref(upToSmallBreakpoint))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_unref(account))
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(MobileWalletBtn, { onToggleMobileWallet: toggleMobileWallet })
              ]))
            : (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(startConnectWithInjectedProvider) && _unref(startConnectWithInjectedProvider)(...args)))
              }, " Connect Wallet "))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})