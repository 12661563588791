import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2e37733c")
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "absolute top-0 right-0 bottom-0 left-0 mx-auto flex w-4 items-center justify-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "font-semibold"
}
_popScopeId()

import { computed } from 'vue';

import { getActiveClassName } from '@/components/utils';
import { StepState } from '@/types';

type Props = {
  title: string;
  steps: Step[];
};

type Step = {
  tooltip: string;
  state: StepState;
  label: number;
  isVisible?: boolean;
};


export default _defineComponent({
  props: {
    title: { type: String, required: true },
    steps: { type: Array, required: true }
  } as unknown as undefined,
  emits: ['navigate'],
  setup(__props: {
  title: string;
  steps: Step[];
}, { emit }) {

const props = __props



/**
 * COMPUTED
 */
const visibleSteps = computed(() => {
  return props.steps.filter(
    step => step.isVisible === undefined || step.isVisible
  );
});

const stepTextClasses = computed(() => {
  return visibleSteps.value.map(step => {
    return getActiveClassName(step.state, [
      [StepState.Active, 'text-white font-semibold hover:text-white'],
      [StepState.Todo, 'text-pink-third font-semibold'],
      [StepState.Success, 'text-pink-third font-semibold'],
      [StepState.Warning, 'text-red-500 font-semibold'],
      [StepState.Completed, 'text-pink-third font-semibold'],
    ]);
  });
});

const stepCircleClasses = computed(() => {
  return visibleSteps.value.map(step => {
    return getActiveClassName(step.state, [
      [StepState.Active, 'border-2 border-pink-secondary text-white active'],
      [StepState.Todo, 'border-2 border-pink-third text-pink-third'],
      [StepState.Success, 'border-2 border-pink-third text-pink-third'],
      [StepState.Warning, 'border-2 border-none bg-red-500 text-white active'],
      [StepState.Completed, 'border-2 border-pink-third text-pink-third'],
    ]);
  });
});

/**
 * FUNCTIONS
 */
function handleNavigate(state: StepState, stepIndex: number) {
  if (state === StepState.Todo) return;
  emit('navigate', stepIndex);
}

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!

  return (_openBlock(), _createBlock(_component_BalStack, {
    horizontal: "",
    spacing: "base",
    class: "p-4",
    justify: "center"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(visibleSteps), (step, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `vertical-step-${step.tooltip}`,
          class: "flex items-center"
        }, [
          _createElementVNode("button", {
            class: _normalizeClass([{ 'cursor-default': step.state === _unref(StepState).Todo }, "bg-none"]),
            onClick: ($event: any) => (handleNavigate(step.state, i))
          }, [
            _createVNode(_component_BalStack, {
              horizontal: "",
              align: "center",
              spacing: "sm"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass([
              'relative flex h-7 w-7 items-center justify-center rounded-full text-sm',
              _unref(stepCircleClasses)[i],
              { 'circle-line': i !== _unref(visibleSteps).length - 1 },
            ])
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    (
                  ![_unref(StepState).Warning, _unref(StepState).Error].includes(step.state)
                )
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(step.label || i + 1), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_4, "!"))
                  ])
                ], 2),
                _createElementVNode("span", {
                  class: _normalizeClass(['text-sm', _unref(stepTextClasses)[i]])
                }, _toDisplayString(step.tooltip), 3)
              ]),
              _: 2
            }, 1024)
          ], 10, _hoisted_1)
        ]))
      }), 128))
    ]),
    _: 1
  }))
}
}

})