import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-303d0170")
const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = { class: "box bg" }
const _hoisted_3 = { class: "txt w-full font-[500px] mt-[12px] grid h-[22px] grid-cols-2 grid-rows-1 items-center justify-between gap-5 text-sm font-medium leading-none text-purple-secondary" }
const _hoisted_4 = { class: "flex flex-row justify-end gap-[8px]" }
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "flex flex-row" }
const _hoisted_8 = ["textContent"]
const _hoisted_9 = { class: "address mb-[12px] flex justify-start" }
const _hoisted_10 = { class: "button p-0" }
const _hoisted_11 = ["textContent"]
const _hoisted_12 = ["textContent"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_IconCheck = _resolveComponent("IconCheck")!
  const _component_IconCopy = _resolveComponent("IconCopy")!
  const _component_IconExplorer = _resolveComponent("IconExplorer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(" Connected with " + _toDisplayString(_ctx.connectorName) + " ", 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "h-[22px] w-[86px] disconnect",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.disconnectWallet && _ctx.disconnectWallet(...args)))
            }, [
              _createElementVNode("div", {
                class: "text-center text-[14px] leading-[18px]",
                textContent: _toDisplayString(_ctx.$t('disconnect'))
              }, null, 8, _hoisted_5)
            ]),
            _createElementVNode("button", {
              class: "h-[22px] w-[68px] change",
              onClick: [
                _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleWalletSelectModal && _ctx.toggleWalletSelectModal(...args))),
                _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
              ]
            }, [
              _createElementVNode("div", {
                class: "text-center text-[14px] leading-[18px]",
                textContent: _toDisplayString(_ctx.$t('change'))
              }, null, 8, _hoisted_6)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Avatar, {
            iconURI: _ctx.profile?.avatar,
            address: _ctx.account,
            size: 24
          }, null, 8, ["iconURI", "address"]),
          _createElementVNode("div", {
            class: "pl-[10px] font-bold text-black dark:text-white address",
            textContent: _toDisplayString(_ctx._shorten(_ctx.account))
          }, null, 8, _hoisted_8)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", _hoisted_10, [
            _createElementVNode("div", {
              class: "link flex flex-row justify-start gap-[8px] align-baseline copy-address",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.copyAddress && _ctx.copyAddress(...args)))
            }, [
              (_ctx.copiedAddress)
                ? (_openBlock(), _createBlock(_component_IconCheck, {
                    key: 0,
                    class: "mt-0.5"
                  }))
                : (_openBlock(), _createBlock(_component_IconCopy, { key: 1 })),
              _createElementVNode("div", {
                class: "text-center",
                textContent: _toDisplayString(_ctx.copiedAddress ? _ctx.$t('copied') : _ctx.$t('copyAddress'))
              }, null, 8, _hoisted_11)
            ])
          ]),
          (_openBlock(), _createBlock(_resolveDynamicComponent('a'), {
            href: _ctx.explorer.addressLink(_ctx.account),
            target: "_blank",
            rel: "noreferrer",
            class: "link ml-[16px] flex flex-row justify-start gap-[8px] align-baseline view"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconExplorer),
              _createElementVNode("div", {
                class: "text-center",
                textContent: _toDisplayString('View on explorer')
              }, null, 8, _hoisted_12)
            ]),
            _: 1
          }, 8, ["href"]))
        ])
      ])
    ])
  ]))
}