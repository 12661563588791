import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-567e288d")
const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform" }
const _hoisted_4 = { key: 3 }
_popScopeId()

import { computed } from 'vue';

import useWeb3 from '@/services/web3/useWeb3';
import { getConnectorLogo } from '@/services/web3/web3.plugin';
import { Step, StepState } from '@/types';

type Props = {
  steps: Step[];
  spacerWidth: number;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    steps: { type: Array, required: true, default: () => [
    { tooltip: 'You did this', state: StepState.Success },
    { tooltip: 'Wallet is tiggered', state: StepState.WalletOpen },
    { tooltip: 'This is pending', state: StepState.Pending },
    { tooltip: 'Do this now', state: StepState.Active },
    { tooltip: 'Do this next', state: StepState.Todo },
  ] },
    spacerWidth: { type: Number, required: true, default: 16 }
  } as unknown as undefined,
  setup(__props: {
  steps: Step[];
  spacerWidth: number;
}) {

const stepState = StepState;

/**
 * TYPES
 */


/**
 * COMPOSABLES
 */
const { connector, provider } = useWeb3();

/**
 * COMPUTED
 */
const walletLogo = computed((): string =>
  getConnectorLogo(connector?.value?.id, provider)
);

/**
 * METHODS
 */
function stateClasses(state: StepState): string {
  switch (state) {
    case StepState.Success:
      return 'border-green-500 dark:border-green-500 text-green-500';
    case StepState.Pending:
      return 'border-none dark:border-none text-orange-500';
    case StepState.Active:
      return 'border-purple-500 dark:border-purple-500 text-gradient';
    case StepState.WalletOpen:
      return 'border-purple-500 dark:border-purple-500 text-gradient';
    default:
      return 'dark:border-gray-700';
  }
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_SpinnerIcon = _resolveComponent("SpinnerIcon")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.steps, (step, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "flex items-center"
      }, [
        (i !== 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['h-px bg-gray-100 dark:bg-gray-700', `w-${__props.spacerWidth}`])
            }, null, 2))
          : _createCommentVNode("", true),
        _createVNode(_component_BalTooltip, {
          text: step.tooltip,
          width: "44",
          textAlign: "center"
        }, {
          activator: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(['step', stateClasses(step.state)])
            }, [
              (step.state === _unref(stepState).Success)
                ? (_openBlock(), _createBlock(_component_BalIcon, {
                    key: 0,
                    name: "check"
                  }))
                : (step.state === _unref(stepState).WalletOpen)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: _unref(walletLogo),
                      class: "h-4 w-4"
                    }, null, 8, _hoisted_2))
                  : (step.state === _unref(stepState).Pending)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(i + 1), 1),
                        _createVNode(_component_SpinnerIcon, { class: "h-8 w-8 animate-spin" })
                      ], 64))
                    : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(i + 1), 1))
            ], 2)
          ]),
          _: 2
        }, 1032, ["text"])
      ]))
    }), 128))
  ]))
}
}

})