import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-19c8c801")
const _hoisted_1 = {
  key: 0,
  class: "balance-indicator"
}
_popScopeId()

import { PoolToken } from '@/services/pool/types';

import BalanceTooltip from './BalanceTooltip.vue';

type Props = {
  hasBalance: boolean;
  symbol: string;
  isSelected: boolean;
  token: PoolToken;
};


export default _defineComponent({
  props: {
    hasBalance: { type: Boolean, required: true, default: false },
    symbol: { type: String, required: true },
    isSelected: { type: Boolean, required: true, default: false },
    token: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  hasBalance: boolean;
  symbol: string;
  isSelected: boolean;
  token: PoolToken;
}) {



return (_ctx: any,_cache: any) => {
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_openBlock(), _createBlock(_component_BalTooltip, {
    disabled: !__props.hasBalance,
    class: "mr-1 cursor-pointer leading-normal last:mr-0",
    textAlign: "left",
    delayMs: 50
  }, {
    activator: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([
          'pill',
          {
            'pill-selected': __props.isSelected,
            'pill-hoverable': __props.hasBalance,
          },
        ])
      }, [
        (__props.hasBalance)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass([
            'pill-text',
            {
              'font-medium': __props.isSelected,
            },
          ])
        }, _toDisplayString(__props.symbol), 3)
      ], 2)
    ]),
    default: _withCtx(() => [
      _createVNode(BalanceTooltip, {
        token: __props.token,
        symbol: __props.symbol
      }, null, 8, ["token", "symbol"])
    ]),
    _: 1
  }, 8, ["disabled"]))
}
}

})