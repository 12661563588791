import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1b632a7c")
const _hoisted_1 = { class: "content-container" }
const _hoisted_2 = { key: 0 }
_popScopeId()

import BalIcon from '../BalIcon/BalIcon.vue';
import {
  useChipClasses,
  useCloseIconClasses,
} from './composables/useChipStyles';

type Props = {
  label?: string;
  closeable?: boolean;
  size?: 'sm' | 'md' | 'lg';
  color?: 'gray' | 'gradient' | 'white' | 'red' | 'orange';
  outline?: boolean;
  rounded?: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    label: { type: String, required: false, default: '' },
    closeable: { type: Boolean, required: false, default: false },
    size: { type: String, required: false, default: 'md' },
    color: { type: String, required: false, default: 'gray' },
    outline: { type: Boolean, required: false, default: true },
    rounded: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  emits: ['closed'],
  setup(__props: {
  label?: string;
  closeable?: boolean;
  size?: 'sm' | 'md' | 'lg';
  color?: 'gray' | 'gradient' | 'white' | 'red' | 'orange';
  outline?: boolean;
  rounded?: boolean;
}, { emit }) {

const props = __props




const classes = useChipClasses(props);
const { classes: iconClasses, iconSize } = useCloseIconClasses(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bal-chip', _unref(classes)])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (__props.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.label), 1))
        : _renderSlot(_ctx.$slots, "default", { key: 1 }),
      (__props.closeable)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('closed')))
          }, [
            _createVNode(BalIcon, {
              name: "x",
              size: _unref(iconSize),
              class: _normalizeClass(['close', _unref(iconClasses)])
            }, null, 8, ["size", "class"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})