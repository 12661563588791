<template>
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <g clip-path="url(#clip0_639_3303)">
            <path
            d="M24 27.9999V9.33325"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M12 20L16 16L20 20"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M20 13.3333L24 9.33325L28 13.3333"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M4 28H28"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M16 28V16"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M4 8L8 4L12 8"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M8 28V4"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_639_3303">
            <rect width="32" height="32" fill="white" />
            </clipPath>
        </defs>
        </svg>
</template>