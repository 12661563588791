
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'BalCard',

  props: {
    title: { type: String, default: '' },
    titleTag: { type: String, default: 'h4' },
    square: { type: Boolean, default: false },
    noPad: { type: Boolean, default: false },
    noContentPad: { type: Boolean, default: false },
    noBorder: { type: Boolean, default: false },
    lighterBg: { type: Boolean, default: false },
    darkBgColor: { type: Boolean, default: false },
    imgSrc: { type: String, default: '' },
    hFull: { type: Boolean, default: false },
    growContent: { type: Boolean, default: false },
    rightAlignHeader: { type: Boolean, default: false },
    exposeOverflow: { type: Boolean, default: false },
    overflowYScroll: { type: Boolean, default: false },
    // shadow: {
    //   type: String,
    //   default: '',
    //   validator: (val: string): boolean => {
    //     return [
    //       '',
    //       'none',
    //       'sm',
    //       'md',
    //       'lg',
    //       'xl',
    //       '2xl',
    //       '3xl',
    //       '4xl',
    //     ].includes(val);
    //   },
    // },
  },

  setup(props) {
    const borderClasses = computed(() => {
      return 'box-border border-[0.5px] border-[rgba(215,179,255,0.5)]';
    });

    const cardClasses = computed(() => {
      return {
        'rounded-lg': !props.square,
        'overflow-hidden': !props.exposeOverflow,
        // 'bg-frame-dark-ultra': !props.lighterBg,
        // 'bg-[#1E0D2C]': props.lighterBg,
        // [`shadow${props.shadow ? '-' : ''}${props.shadow}`]: true,
        // [borderClasses.value]: !props.noBorder,
        'h-full': props.hFull,
      };
    });
    const containerClasses = computed(() => {
      return {
        'default-back': !props.darkBgColor && !props.lighterBg,
        'dark-back': props.darkBgColor && !props.lighterBg,
        'light-back': !props.darkBgColor && props.lighterBg,
      };
    });

    const headerClasses = computed(() => {
      return {
        'p-4 pb-0': !props.noPad,
      };
    });

    const headerContentClasses = computed(() => {
      return {
        'justify-end': props.rightAlignHeader,
      };
    });

    const contentClasses = computed(() => {
      return {
        'p-4': !props.noPad && !props.noContentPad,
        'flex-grow': props.growContent,
      };
    });

    const footerClasses = computed(() => {
      return {
        'rounded-b-lg': !props.square,
        'p-2 pt-0': !props.noPad,
      };
    });

    const featureStyles = computed(() => ({
      backgroundImage: `url('${props.imgSrc}')`,
    }));

    return {
      cardClasses,
      contentClasses,
      headerClasses,
      headerContentClasses,
      footerClasses,
      featureStyles,
      containerClasses,
    };
  },
});
