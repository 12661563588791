import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-bb239fea")
const _hoisted_1 = ["loading", "loadingLabel", "size", "circle"]
const _hoisted_2 = ["textContent"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_AppNavSettings = _resolveComponent("AppNavSettings")!
  const _component_BalPopover = _resolveComponent("BalPopover")!

  return (_openBlock(), _createBlock(_component_BalPopover, {
    noPad: "",
    align: _ctx.isMobile ? 'center' : undefined,
    detached: _ctx.isMobile ? true : undefined
  }, {
    activator: _withCtx(() => [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleModal && _ctx.toggleModal(...args))),
        class: _normalizeClass(["btn flex flex-row text-base w-full lg:w-auto justify-center", { btn: _ctx.upToLargeBreakpoint }]),
        loading: _ctx.isLoadingProfile,
        loadingLabel: _ctx.upToLargeBreakpoint ? '' : _ctx.$t('connecting'),
        color: "white",
        size: _ctx.upToLargeBreakpoint ? 'md' : 'sm',
        circle: _ctx.upToLargeBreakpoint
      }, [
        _createVNode(_component_Avatar, {
          iconURI: _ctx.profile?.avatar,
          address: _ctx.account,
          size: _ctx.avatarSize
        }, null, 8, ["iconURI", "address", "size"]),
        _createElementVNode("span", {
          class: "eth-address inline-block pl-2 leading-[20px]",
          textContent: _toDisplayString(_ctx._shorten(_ctx.account))
        }, null, 8, _hoisted_2)
      ], 10, _hoisted_1)
    ]),
    default: _withCtx(() => [
      (_ctx.showModal)
        ? (_openBlock(), _createBlock(_component_AppNavSettings, {
            key: 0,
            close: _ctx.closeModal
          }, null, 8, ["close"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        onHide: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hidePopover && _ctx.hidePopover(...args)))
      }, null, 32)
    ]),
    _: 1
  }, 8, ["align", "detached"]))
}