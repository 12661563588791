import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1090be26")
const _hoisted_1 = {
  key: 1,
  class: "container"
}
const _hoisted_2 = { class: "relative h-full bg-transparent p-4" }
const _hoisted_3 = {
  key: 0,
  class: "flex"
}
const _hoisted_4 = { class: "title font-medium" }
const _hoisted_5 = {
  key: 1,
  class: "flex h-full w-full items-center justify-center"
}
const _hoisted_6 = { class: "text-sm text-gray-400" }
const _hoisted_7 = {
  key: 2,
  class: "flex h-full w-full items-center justify-center"
}
const _hoisted_8 = { class: "text-center text-sm text-gray-400" }
const _hoisted_9 = {
  key: 3,
  class: "flex-col"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "mr-4 text-sm text-gray-500" }
const _hoisted_12 = { class: "text-sm text-gray-500" }
const _hoisted_13 = {
  key: 1,
  class: "-mt-2 lg:mt-2"
}
const _hoisted_14 = { class: "flex w-full justify-end text-sm text-gray-500" }
_popScopeId()

import { format, fromUnixTime } from 'date-fns';
import {
  Dictionary,
  mapKeys,
  mapValues,
  maxBy,
  minBy,
  pickBy,
  toPairs,
} from 'lodash';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from 'vue-query';
import { useStore } from 'vuex';

import { useTradeState } from '@/composables/trade/useTradeState';
import useBreakpoints from '@/composables/useBreakpoints';
import useTailwind from '@/composables/useTailwind';
import useTokens from '@/composables/useTokens';
import QUERY_KEYS from '@/constants/queryKeys';
import { coingeckoService } from '@/services/coingecko/coingecko.service';
import useWeb3 from '@/services/web3/useWeb3';

/**
 *
 * @param inputAsset The address of the input asset
 * @param outputAsset The address of the output asset
 * @param nativeAsset The address of the native asset on the network
 * @param wrappedNativeAsset The address of the wrapped native asset on the network
 * @param days The number of days to pull historical data for
 * @param inverse Swaps the pricing calc to be output/input rather than input/output
 */
type Props = {
  isModal?: boolean;
  onCloseModal?: () => void;
  toggleModal: () => void;
};


export default _defineComponent({
  props: {
    isModal: { type: Boolean, required: false },
    onCloseModal: { type: Function, required: false },
    toggleModal: { type: Function, required: true }
  } as unknown as undefined,
  setup(__props: {
  isModal?: boolean;
  onCloseModal?: () => void;
  toggleModal: () => void;
}) {

const props = __props
async function getPairPriceData(
  inputAsset: string,
  outputAsset: string,
  nativeAsset: string,
  wrappedNativeAsset: string,
  days: number,
  inverse?: boolean
) {
  let _inputAsset =
    inputAsset === nativeAsset ? wrappedNativeAsset : inputAsset;
  let _outputAsset =
    outputAsset === nativeAsset ? wrappedNativeAsset : outputAsset;

  if (inverse) {
    [_inputAsset, _outputAsset] = [_outputAsset, _inputAsset];
  }
  const aggregateBy = days === 1 ? 'hour' : 'day';
  const getInputAssetData = coingeckoService.prices.getTokensHistorical(
    [_inputAsset],
    days,
    1,
    aggregateBy
  );

  const getOutputAssetData = coingeckoService.prices.getTokensHistorical(
    [_outputAsset],
    days,
    1,
    aggregateBy
  );

  const [inputAssetData, outputAssetData] = await Promise.all([
    getInputAssetData,
    getOutputAssetData,
  ]);

  const calculatedPricing = mapValues(inputAssetData, (value, timestamp) => {
    if (!outputAssetData[timestamp]) return null;
    return (1 / value[0]) * outputAssetData[timestamp][0];
  });

  const calculatedPricingNoNulls = pickBy(
    calculatedPricing
  ) as Dictionary<number>;

  const formatTimestamps = mapKeys(
    calculatedPricingNoNulls,
    (_, timestamp: any) =>
      format(fromUnixTime(timestamp / 1000), 'yyyy/MM/dd HH:mm')
  );

  return toPairs(formatTimestamps);
}

const chartTimespans = [
  {
    option: '1d',
    value: 1,
  },
  {
    option: '1w',
    value: 7,
  },
  {
    option: '1m',
    value: 30,
  },
  {
    option: '1y',
    value: 365,
  },
  {
    option: 'All',
    value: 4000,
  },
];


const { upToLargeBreakpoint } = useBreakpoints();
const { t } = useI18n();
const store = useStore();
const { getToken, wrappedNativeAsset, nativeAsset } = useTokens();
const { tokenInAddress, tokenOutAddress, initialized } = useTradeState();
const tailwind = useTailwind();
const { chainId: userNetworkId, appNetworkConfig } = useWeb3();

const chartHeight = ref(
  upToLargeBreakpoint ? (props.isModal ? 250 : 75) : props.isModal ? 250 : 100
);
const activeTimespan = ref(chartTimespans[0]);
const appLoading = computed(() => store.state.app.loading);

const inputSym = computed(() => {
  if (tokenInAddress.value === '') return 'Unknown';
  return getToken(tokenInAddress.value)?.symbol;
});
const outputSym = computed(() => {
  if (tokenOutAddress.value === '') return 'Unknown';
  return getToken(tokenOutAddress.value)?.symbol;
});

const dataMin = computed(() => {
  return (minBy(priceData.value || [], v => v[1]) || [])[1] || 0;
});

const dataMax = computed(() => {
  return (maxBy(priceData.value || [], v => v[1]) || [])[1] || 0;
});

const {
  isLoading: isLoadingPriceData,
  data: priceData,
  error: failedToLoadPriceData,
} = useQuery(
  QUERY_KEYS.Tokens.PairPriceData(
    tokenInAddress,
    tokenOutAddress,
    activeTimespan,
    userNetworkId,
    nativeAsset,
    wrappedNativeAsset
  ),
  () =>
    getPairPriceData(
      tokenInAddress.value,
      tokenOutAddress.value,
      nativeAsset?.address,
      wrappedNativeAsset.value?.address,
      activeTimespan.value.value,
      true
    ),
  reactive({
    enabled: initialized,
    retry: false,
    // when refetch on window focus in enabled, it causes a flash
    // in the loading state of the card which is jarring. disabling it
    refetchOnWindowFocus: false,
  })
);

const toggle = () => {
  props.toggleModal();
};

const equivalentTokenPairs = [
  [appNetworkConfig.addresses.weth, appNetworkConfig.nativeAsset.address],
];

const allChartValuesEqual = computed(() =>
  equivalentTokenPairs.some(
    pair =>
      pair.includes(tokenInAddress.value) &&
      pair.includes(tokenOutAddress.value)
  )
);

const chartData = computed(() => {
  if (allChartValuesEqual.value) return [];
  return [
    {
      name: `${outputSym.value}/${inputSym.value}`,
      values: priceData.value || [],
    },
  ];
});

const chartBlankText = computed(() => {
  if (allChartValuesEqual.value) {
    return `${inputSym.value} -> ${outputSym.value} is 1:1`;
  }
  return t('noData');
});

const isNegativeTrend = computed(() => {
  const _priceData = priceData.value || [];
  if (_priceData.length > 2) {
    if (
      _priceData[_priceData.length - 1][1] <
      _priceData[_priceData.length - 2][1]
    ) {
      return true;
    }
  }
  return false;
});

const chartColors = computed(() => {
  let color = tailwind.theme.colors.green['400'];
  if (isNegativeTrend.value) color = tailwind.theme.colors.red['400'];
  return [color];
});

const chartGrid = computed(() => {
  return {
    left: '2.5%',
    right: '0',
    top: '10%',
    bottom: '15%',
    containLabel: false,
  };
});

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBlankSlate = _resolveComponent("BalBlankSlate")!
  const _component_BalChart = _resolveComponent("BalChart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      '',
      {
        'h-40 lg:h-56': !__props.isModal,
        'h-full lg:h-full': __props.isModal,
      },
    ])
  }, [
    (_unref(isLoadingPriceData))
      ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
          key: 0,
          class: _normalizeClass({
        'h-64': !__props.isModal,
        'h-112': __props.isModal,
      })
        }, null, 8, ["class"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (!_unref(failedToLoadPriceData) && !(_unref(isLoadingPriceData) || _unref(appLoading)))
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("h6", _hoisted_4, _toDisplayString(_unref(outputSym)) + "/" + _toDisplayString(_unref(inputSym)), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(failedToLoadPriceData) && _unref(tokenOutAddress))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('insufficientData')), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(failedToLoadPriceData) && !_unref(tokenOutAddress))
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('chooseAPair')), 1)
                ]))
              : _createCommentVNode("", true),
            (!_unref(failedToLoadPriceData) && !_unref(isLoadingPriceData))
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_unref(chartData).length === 0)
                    ? (_openBlock(), _createBlock(_component_BalBlankSlate, {
                        key: 0,
                        class: _normalizeClass(['mt-4', __props.isModal ? 'h-96' : 'h-40'])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BalIcon, { name: "bar-chart" }),
                          _createTextVNode(" " + _toDisplayString(_unref(chartBlankText)), 1)
                        ]),
                        _: 1
                      }, 8, ["class"]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createVNode(_component_BalChart, {
                          data: _unref(chartData),
                          height: chartHeight.value,
                          showLegend: false,
                          color: _unref(chartColors),
                          customGrid: _unref(chartGrid),
                          axisLabelFormatter: { yAxis: '0.000000' },
                          wrapperClass: [
                'flex flex-row lg:flex-col',
                {
                  'flex-row': !__props.isModal,
                  'flex-col': __props.isModal,
                },
              ],
                          showTooltip: !_unref(upToLargeBreakpoint) || __props.isModal,
                          chartType: "line",
                          hideYAxis: "",
                          hideXAxis: "",
                          showHeader: "",
                          useMinMax: ""
                        }, null, 8, ["data", "height", "color", "customGrid", "axisLabelFormatter", "wrapperClass", "showTooltip"]),
                        (__props.isModal)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass([
                'mt-6 flex w-full justify-between',
                {
                  'flex-col': __props.isModal,
                },
              ])
                            }, [
                              _createElementVNode("div", null, [
                                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(chartTimespans, (timespan) => {
                                  return _createElementVNode("button", {
                                    key: timespan.value,
                                    class: _normalizeClass([
                    'mr-2 rounded-lg py-1 px-2 text-sm',
                    {
                      'text-white': activeTimespan.value.value === timespan.value,
                      'text-secondary': activeTimespan.value.value !== timespan.value,
                      'bg-green-400':
                        !_unref(isNegativeTrend) &&
                        activeTimespan.value.value === timespan.value,
                      'bg-red-400':
                        _unref(isNegativeTrend) &&
                        activeTimespan.value.value === timespan.value,
                      'hover:bg-red-200': _unref(isNegativeTrend),
                      'hover:bg-green-200': !_unref(isNegativeTrend),
                    },
                  ]),
                                    onClick: ($event: any) => (activeTimespan.value = timespan)
                                  }, _toDisplayString(timespan.option), 11, _hoisted_10)
                                }), 64))
                              ]),
                              _createElementVNode("div", {
                                class: _normalizeClass({ 'mt-4': __props.isModal })
                              }, [
                                _createElementVNode("span", _hoisted_11, "Low: " + _toDisplayString(_unref(dataMin).toPrecision(6)), 1),
                                _createElementVNode("span", _hoisted_12, "High: " + _toDisplayString(_unref(dataMax).toPrecision(6)), 1)
                              ], 2)
                            ], 2))
                          : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              _createElementVNode("span", _hoisted_14, _toDisplayString(activeTimespan.value.option), 1)
                            ]))
                      ], 64))
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
  ], 2))
}
}

})