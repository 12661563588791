
import { computed, defineComponent } from 'vue';

import Avatar from '@/components/images/Avatar.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useWeb3 from '@/services/web3/useWeb3';

import AppNavSettings from './AppNavSettings.vue';

export default defineComponent({
  name: 'AppNavAccountBtn',

  data() {
    return {
      showModal: false,
    };
  },

  methods: {
    toggleModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },

  components: {
    AppNavSettings,
    Avatar,
  },

  setup() {
    const { bp, upToLargeBreakpoint, isMobile } = useBreakpoints();
    const { isLoadingProfile, profile, account } = useWeb3();

    const avatarSize = computed(() => {
      if (bp.value === 'sm') {
        return 20;
      } else if (['md', 'lg'].includes(bp.value)) {
        return 20;
      } else {
        return 20;
      }
    });

    return {
      // computed
      bp,
      account,
      profile,
      avatarSize,
      upToLargeBreakpoint,
      isLoadingProfile,
      isMobile,
    };
  },
});
