<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.3 7H0.7C0.514348 7 0.336301 7.07375 0.205025 7.20503C0.0737498 7.3363 0 7.51435 0 7.7V13.3C0 13.4857 0.0737498 13.6637 0.205025 13.795C0.336301 13.9263 0.514348 14 0.7 14H6.3C6.48565 14 6.6637 13.9263 6.79497 13.795C6.92625 13.6637 7 13.4857 7 13.3V7.7C7 7.51435 6.92625 7.3363 6.79497 7.20503C6.6637 7.07375 6.48565 7 6.3 7ZM5.6 12.6H1.4V8.4H5.6V12.6ZM13.944 0.434C13.873 0.262955 13.737 0.127032 13.566 0.0559999C13.4818 0.0201313 13.3915 0.00110599 13.3 0H9.1C8.91435 0 8.7363 0.0737498 8.60503 0.205025C8.47375 0.336301 8.4 0.514348 8.4 0.7C8.4 0.885652 8.47375 1.0637 8.60503 1.19497C8.7363 1.32625 8.91435 1.4 9.1 1.4H11.613L7.903 5.103C7.83739 5.16807 7.78531 5.24549 7.74978 5.3308C7.71424 5.4161 7.69594 5.50759 7.69594 5.6C7.69594 5.69241 7.71424 5.7839 7.74978 5.8692C7.78531 5.95451 7.83739 6.03193 7.903 6.097C7.96807 6.16261 8.04549 6.21469 8.1308 6.25022C8.2161 6.28576 8.30759 6.30406 8.4 6.30406C8.49241 6.30406 8.5839 6.28576 8.6692 6.25022C8.75451 6.21469 8.83193 6.16261 8.897 6.097L12.6 2.387V4.9C12.6 5.08565 12.6737 5.2637 12.805 5.39497C12.9363 5.52625 13.1143 5.6 13.3 5.6C13.4857 5.6 13.6637 5.52625 13.795 5.39497C13.9263 5.2637 14 5.08565 14 4.9V0.7C13.9989 0.608526 13.9799 0.518156 13.944 0.434Z"
    />
  </svg>
</template>
