import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalAsset = _resolveComponent("BalAsset")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assetChunks, (assetChunk, assetChunkIndex) => {
    return (_openBlock(), _createElementBlock("div", {
      key: assetChunkIndex,
      class: _normalizeClass(['addresses-row flex-none', _ctx.assetRowClasses]),
      style: _normalizeStyle({
        width: `${_ctx.width}px`,
        height: `${_ctx.size}px`,
      })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assetChunk, (addressOrURI, i) => {
        return (_openBlock(), _createBlock(_component_BalAsset, _mergeProps({ key: i }, { ..._ctx.assetAttrsFor(addressOrURI), ..._ctx.balAssetProps }, {
          size: _ctx.size,
          class: ['token-icon', _ctx.$props.borderClass,{ absolute: !_ctx.wrap, relative: _ctx.wrap }],
          style: {
          left: `${_ctx.leftOffsetFor(i)}px`,
          zIndex: `${20 - i}`,
          width: `${_ctx.size}px`,
          height: `${_ctx.size}px`,
        },
          bgImage: _ctx.backImage,
          onClick: ($event: any) => (_ctx.$emit('click', addressOrURI))
        }), null, 16, ["size", "class", "style", "bgImage", "onClick"]))
      }), 128))
    ], 6))
  }), 128))
}