<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9 0H2.1C1.54305 0 1.0089 0.221249 0.615076 0.615076C0.221249 1.0089 0 1.54305 0 2.1V11.9C0 12.457 0.221249 12.9911 0.615076 13.3849C1.0089 13.7788 1.54305 14 2.1 14H11.9C12.457 14 12.9911 13.7788 13.3849 13.3849C13.7788 12.9911 14 12.457 14 11.9V2.1C14 1.54305 13.7788 1.0089 13.3849 0.615076C12.9911 0.221249 12.457 0 11.9 0ZM5.6 1.4H8.4V3.591L7.385 2.891C7.2707 2.81573 7.13685 2.77562 7 2.77562C6.86315 2.77562 6.72929 2.81573 6.615 2.891L5.6 3.591V1.4ZM12.6 11.9C12.6 12.0857 12.5263 12.2637 12.395 12.395C12.2637 12.5263 12.0857 12.6 11.9 12.6H2.1C1.91435 12.6 1.7363 12.5263 1.60503 12.395C1.47375 12.2637 1.4 12.0857 1.4 11.9V2.1C1.4 1.91435 1.47375 1.7363 1.60503 1.60503C1.7363 1.47375 1.91435 1.4 2.1 1.4H4.2V4.9C4.20034 5.02663 4.23502 5.1508 4.30035 5.25928C4.36569 5.36776 4.45922 5.45648 4.571 5.516C4.68046 5.5722 4.8028 5.59856 4.9257 5.59242C5.04859 5.58627 5.16769 5.54784 5.271 5.481L7 4.34L8.715 5.481C8.82028 5.55033 8.94233 5.58995 9.06826 5.59567C9.19419 5.60139 9.31932 5.57299 9.43045 5.51349C9.54158 5.45398 9.63458 5.36556 9.69963 5.25758C9.76467 5.1496 9.79935 5.02606 9.8 4.9V1.4H11.9C12.0857 1.4 12.2637 1.47375 12.395 1.60503C12.5263 1.7363 12.6 1.91435 12.6 2.1V11.9Z"
    />
  </svg>
</template>
