<template>
    <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M18.3332 9.00024C22.0151 9.00024 24.9998 7.20938 24.9998 5.00024C24.9998 2.79111 22.0151 1.00024 18.3332 1.00024C14.6513 1.00024 11.6665 2.79111 11.6665 5.00024C11.6665 7.20938 14.6513 9.00024 18.3332 9.00024Z"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M11.6665 5.00024V10.3336C11.6665 12.5429 14.6518 14.3336 18.3332 14.3336C22.0145 14.3336 24.9998 12.5429 24.9998 10.3336V5.00024"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M11.6665 10.3337V15.6671C11.6665 17.8764 14.6518 19.6671 18.3332 19.6671C22.0145 19.6671 24.9998 17.8764 24.9998 15.6671V10.3337"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M11.6665 15.667V21.0003C11.6665 23.2097 14.6518 25.0003 18.3332 25.0003C22.0145 25.0003 24.9998 23.2097 24.9998 21.0003V15.667"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M6.33333 9.00024H3C2.46957 9.00024 1.96086 9.21096 1.58579 9.58603C1.21071 9.9611 1 10.4698 1 11.0002C1 11.5307 1.21071 12.0394 1.58579 12.4145C1.96086 12.7895 2.46957 13.0002 3 13.0002H4.33333C4.86377 13.0002 5.37247 13.211 5.74755 13.586C6.12262 13.9611 6.33333 14.4698 6.33333 15.0002C6.33333 15.5307 6.12262 16.0394 5.74755 16.4145C5.37247 16.7895 4.86377 17.0002 4.33333 17.0002H1"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M3.6665 17.0003V18.3337M3.6665 7.66699V9.00033"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        </svg>
</template>