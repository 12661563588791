<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_639_3270)">
    <path d="M22.6668 10.6666V6.66659C22.6668 6.31296 22.5264 5.97383 22.2763 5.72378C22.0263 5.47373 21.6871 5.33325 21.3335 5.33325H8.00016C7.29292 5.33325 6.61464 5.6142 6.11454 6.1143C5.61445 6.6144 5.3335 7.29267 5.3335 7.99992M5.3335 7.99992C5.3335 8.70716 5.61445 9.38544 6.11454 9.88554C6.61464 10.3856 7.29292 10.6666 8.00016 10.6666H24.0002C24.3538 10.6666 24.6929 10.8071 24.943 11.0571C25.193 11.3072 25.3335 11.6463 25.3335 11.9999V15.9999M5.3335 7.99992V23.9999C5.3335 24.7072 5.61445 25.3854 6.11454 25.8855C6.61464 26.3856 7.29292 26.6666 8.00016 26.6666H24.0002C24.3538 26.6666 24.6929 26.5261 24.943 26.2761C25.193 26.026 25.3335 25.6869 25.3335 25.3333V21.3333" stroke="#BDB2DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26.6665 16V21.3333H21.3332C20.6259 21.3333 19.9477 21.0524 19.4476 20.5523C18.9475 20.0522 18.6665 19.3739 18.6665 18.6667C18.6665 17.9594 18.9475 17.2811 19.4476 16.781C19.9477 16.281 20.6259 16 21.3332 16H26.6665Z" stroke="#BDB2DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_639_3270">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
    </svg>
</template>