import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-30b4a466")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src"]
_popScopeId()

import { computed, ref, toRefs, watch } from 'vue';

import useTokens from '@/composables/useTokens';
import useUrls from '@/composables/useUrls';
import { TokenInfo } from '@/types/TokenList';
import Avatar from '../../images/Avatar.vue';

type Props = {
  address?: string;
  iconURI?: string;
  size?: number;
  button?: boolean;
  bgImage?: string;
};


export default _defineComponent({
  props: {
    address: { type: String, required: false, default: '' },
    iconURI: { type: String, required: false, default: '' },
    size: { type: Number, required: false, default: 24 },
    button: { type: Boolean, required: false, default: false },
    bgImage: { type: String, required: false }
  } as unknown as undefined,
  setup(__props: {
  address?: string;
  iconURI?: string;
  size?: number;
  button?: boolean;
  bgImage?: string;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { getToken } = useTokens();
const { resolve } = useUrls();

/**
 * STATE
 */
const { address } = toRefs(props);
const error = ref(false);

/**
 * COMPUTED
 */
const token = computed<TokenInfo | undefined>(() => getToken(address.value));

const iconSRC = computed(() => {
  if (props.iconURI) return resolve(props.iconURI);

  if (!token.value?.logoURI) return '';
  return resolve(token.value.logoURI);
});

const rootElement = computed(() => (props.button ? 'button' : 'div'));

const rootElementAttrs = computed(() => ({
  'aria-label': token.value?.symbol,
}));

/**
 * WATCHERS
 */
watch(iconSRC, newURL => {
  if (newURL !== '') error.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(rootElement)), _mergeProps({
    class: "bal-asset inline-block rounded-full leading-none shadow-sm",
    style: {
      width: `${__props.size}px`,
      height: `${__props.size}px`,
    }
  }, _unref(rootElementAttrs)), {
    default: _withCtx(() => [
      (_unref(iconSRC) && !error.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (__props.bgImage)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: __props.bgImage,
                  class: "rounded-full bg-transparent absolute",
                  onError: _cache[0] || (_cache[0] = ($event: any) => (error.value = true))
                }, null, 40, _hoisted_2))
              : _createCommentVNode("", true),
            _createElementVNode("img", {
              src: _unref(iconSRC),
              class: "rounded-full bg-transparent",
              onError: _cache[1] || (_cache[1] = ($event: any) => (error.value = true))
            }, null, 40, _hoisted_3)
          ]))
        : (!!_unref(address))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (__props.bgImage)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: __props.bgImage,
                    class: "rounded-full bg-transparent absolute",
                    onError: _cache[2] || (_cache[2] = ($event: any) => (error.value = true))
                  }, null, 40, _hoisted_5))
                : _createCommentVNode("", true),
              _createVNode(Avatar, {
                address: _unref(address),
                size: __props.size
              }, null, 8, ["address", "size"])
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "overflow-visible rounded-full bg-gray-300 dark:bg-gray-700",
              style: _normalizeStyle({
        width: `${__props.size}px`,
        height: `${__props.size}px`,
      })
            }, null, 4))
    ]),
    _: 1
  }, 16, ["style"]))
}
}

})