<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_699_6333)">
        <path d="M12 5.00024V19.0002" stroke="#FDFDFD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 12H19" stroke="#FDFDFD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_699_6333">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>
