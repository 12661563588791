import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5c4c249f")
const _hoisted_1 = { class: "layout-container" }
_popScopeId()

import { computed } from 'vue';

/**
 * TYPES
 */
type Props = {
  mobileGuttersFirst?: boolean;
  mobileGuttersLast?: boolean;
  mobileHideGutters?: boolean;
  offsetGutters?: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    mobileGuttersFirst: { type: Boolean, required: false, default: false },
    mobileGuttersLast: { type: Boolean, required: false, default: false },
    mobileHideGutters: { type: Boolean, required: false, default: false },
    offsetGutters: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  setup(__props: {
  mobileGuttersFirst?: boolean;
  mobileGuttersLast?: boolean;
  mobileHideGutters?: boolean;
  offsetGutters?: boolean;
}) {

const props = __props


/**
 * COMPUTED
 */
const gutterClasses = computed(() => ({
  'order-1 lg:order-2': props.mobileGuttersFirst,
  'order-3 lg:order-2': props.mobileGuttersLast,
  'hidden lg:block': props.mobileHideGutters,
  'mt-6': props.offsetGutters,
}));

const centerClasses = computed(() => ({
  'order-2': props.mobileGuttersFirst,
  'order-1 lg:order-2': props.mobileGuttersLast,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['gutter-col', _unref(gutterClasses)])
    }, [
      _renderSlot(_ctx.$slots, "gutterLeft")
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(['center-col', _unref(centerClasses)])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(['gutter-col', _unref(gutterClasses)])
    }, [
      _renderSlot(_ctx.$slots, "gutterRight")
    ], 2)
  ]))
}
}

})