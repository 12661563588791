import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed, onMounted, ref, watch } from 'vue';

import useUrls from '@/composables/useUrls';

/**
 * TYPES
 */
type Props = {
  address: string;
  iconURI?: string;
  size?: number;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    address: { type: String, required: true },
    iconURI: { type: String, required: false, default: '' },
    size: { type: Number, required: false, default: 24 }
  } as unknown as undefined,
  setup(__props: {
  address: string;
  iconURI?: string;
  size?: number;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { resolve } = useUrls();

/**
 * STATE
 */
const error = ref(false);
const isMounted = ref(false);

/**
 * COMPUTED
 */
const iconSRC = computed(() => resolve(props.iconURI));

/**
 * WATCHERS
 */
watch(iconSRC, newURL => {
  if (newURL !== '') error.value = false;
});

/**
 * LIFECYCLE
 */
onMounted(() => (isMounted.value = true));

return (_ctx: any,_cache: any) => {
  const _component_Jazzicon = _resolveComponent("Jazzicon")!

  return (_unref(iconSRC) && !error.value)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _unref(iconSRC),
        style: _normalizeStyle({
      width: `${__props.size}px`,
      height: `${__props.size}px`,
      background: 'white',
    }),
        class: "inline-block rounded-full leading-none shadow-sm",
        onError: _cache[0] || (_cache[0] = ($event: any) => (error.value = true))
      }, null, 44, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "overflow-hidden rounded-full leading-none",
        style: _normalizeStyle({
      width: `${__props.size}px`,
      height: `${__props.size}px`,
    })
      }, [
        (isMounted.value)
          ? (_openBlock(), _createBlock(_component_Jazzicon, {
              key: 0,
              address: __props.address,
              diameter: __props.size
            }, null, 8, ["address", "diameter"]))
          : _createCommentVNode("", true)
      ], 4))
}
}

})