import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5f31462a")
const _hoisted_1 = {
  key: 0,
  class: "text-center coming-soon-text overflow"
}
const _hoisted_2 = {
  key: 1,
  class: "text-center coming-soon-text mobile overflow"
}
_popScopeId()

import { Network } from '@balancer-labs/sdk';
import { computed, ref } from 'vue';

import BalModal from '@/components/_global/BalModal/BalModal.vue';
import { urlFor } from '@/composables/useNetwork';
import useVeBAL from '@/composables/useVeBAL';
import useBreakpoints from '@/composables/useBreakpoints';


export default _defineComponent({
  setup(__props) {

const { isDesktop, isMobile } = useBreakpoints();

/**
 * STATE
 */
const redirectModal = ref<typeof BalModal>();

/**
 * COMPOSABLES
 */
const { showRedirectModal, setShowRedirectModal } = useVeBAL();

/**
 * COMPUTED
 */
const mainnetURL = computed((): string => `${urlFor(Network.MAINNET)}/vebal`);

/**
 * METHODS
 */
function handleInternalClose() {
  redirectModal?.value?.hide();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BalModal, {
    ref: redirectModal,
    show: _unref(showRedirectModal),
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(setShowRedirectModal)(false)))
  }, {
    default: _withCtx(() => [
      (_unref(isDesktop))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Coming Soon "))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, " Coming Soon "))
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})