import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId } from "vue"

import { computed } from 'vue';

type Props = {
  active: boolean;
};


export default _defineComponent({
  props: {
    active: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  active: boolean;
}) {

const props = __props


const classes = computed(() => ({
  // 'border-white dark:border-gray-900': !props.active,
  // 'gradient-underline': props.active,
}));

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: _normalizeClass(['desktop-link-item', _unref(classes)])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})