import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3855325d")
const _hoisted_1 = { class: "items-grid grid grid-flow-col grid-rows-1 gap-2" }
const _hoisted_2 = ["size"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
_popScopeId()

import { computed } from 'vue';

import DarkModeToggle from '@/components/btns/DarkModeToggle.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import { useSidebar } from '@/composables/useSidebar';
import useWeb3 from '@/services/web3/useWeb3';

import AppNavAccountBtn from './AppNavAccountBtn.vue';
import AppNavActivityBtn from './AppNavActivityBtn/AppNavActivityBtn.vue';
import AppNavMenu from './AppNavMenu.vue';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const { isMobile, isDesktop, upToSmallBreakpoint } = useBreakpoints();
const { account, connector, startConnectWithInjectedProvider } = useWeb3();
const { setSidebarOpen } = useSidebar();

/**
 * COMPUTED
 */
const hideNetworkSelect = computed(() => connector.value?.id === 'gnosis');

return (_ctx: any,_cache: any) => {
  const _component_MenuBurgerIcon = _resolveComponent("MenuBurgerIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(account) && !_unref(upToSmallBreakpoint))
      ? (_openBlock(), _createBlock(AppNavAccountBtn, { key: 0 }))
      : (_unref(isDesktop))
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "btn",
            size: _unref(isMobile) ? 'md' : 'sm',
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(startConnectWithInjectedProvider) && _unref(startConnectWithInjectedProvider)(...args)))
          }, [
            _createElementVNode("span", {
              class: "hidden lg:inline-block w-[100px]",
              textContent: _toDisplayString(_ctx.$t('connectWallet'))
            }, null, 8, _hoisted_3),
            _createElementVNode("span", {
              class: "lg:hidden",
              textContent: _toDisplayString(_ctx.$t('connect'))
            }, null, 8, _hoisted_4)
          ], 8, _hoisted_2))
        : _createCommentVNode("", true),
    _createVNode(AppNavMenu),
    (_unref(isMobile))
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(setSidebarOpen)(true))),
          class: "menu-btn"
        }, [
          _createVNode(_component_MenuBurgerIcon)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})