import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3168df8a")
const _hoisted_1 = {
  noPad: "",
  growContent: "",
  shadow: "none"
}
const _hoisted_2 = { class: "container flex h-full w-full flex-col" }
const _hoisted_3 = { class: "flex p-3 pb-0 lg:justify-between lg:pb-3" }
const _hoisted_4 = {
  key: 0,
  class: "title"
}
const _hoisted_5 = {
  key: 1,
  class: "ml-1 font-semibold lg:ml-0 lg:font-normal"
}
const _hoisted_6 = {
  key: 0,
  class: "mr-0.5"
}
_popScopeId()

import { take } from 'lodash';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useTradeState } from '@/composables/trade/useTradeState';
import useBreakpoints from '@/composables/useBreakpoints';
import { isMainnet } from '@/composables/useNetwork';
import useTokens from '@/composables/useTokens';
import { configService } from '@/services/config/config.service';
import useWeb3 from '@/services/web3/useWeb3';


export default _defineComponent({
  setup(__props) {

const { appNetworkConfig, isWalletReady, startConnectWithInjectedProvider } =
  useWeb3();
const { upToLargeBreakpoint } = useBreakpoints();
const { setTokenInAddress } = useTradeState();
const {
  hasBalance,
  nativeAsset,
  balanceFor,
  balances,
  dynamicDataLoading: isLoadingBalances,
} = useTokens();
const nativeCurrency = configService.network.nativeAsset.symbol;
const networkName = configService.network.name;
const { t } = useI18n();

const etherBalance = computed(() => {
  if (!isWalletReady.value) return '-';
  return Number(balanceFor(appNetworkConfig.nativeAsset.address)).toFixed(4);
});

const noNativeCurrencyMessage = computed(() => {
  return t('noNativeCurrency', [nativeCurrency, networkName]);
});

const noNativeCurrencyMessageEthereum = computed(() => {
  return t('noNativeCurrencyEthereum', [nativeCurrency, networkName]);
});

const noTokensMessage = computed(() => {
  return t('noTokensInWallet', [networkName]);
});

const tokensWithBalance = computed(() => {
  return take(
    Object.keys(balances.value).filter(
      tokenAddress =>
        Number(balances.value[tokenAddress]) > 0 &&
        tokenAddress !== appNetworkConfig.nativeAsset.address &&
        tokenAddress !== appNetworkConfig.addresses.veBAL
    ),
    21
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_unref(upToLargeBreakpoint))
          ? (_openBlock(), _createElementBlock("h6", _hoisted_4, _toDisplayString(_ctx.$t('myWallet2')), 1))
          : _createCommentVNode("", true),
        (!_unref(isLoadingBalances))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (!_unref(hasBalance)(_unref(nativeAsset).address))
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(nativeCurrency)), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})