<template>
    <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <g clip-path="url(#clip0_639_3279)">
            <path
            d="M11.0002 16H5.66683C4.93045 16 4.3335 16.597 4.3335 17.3333V25.3333C4.3335 26.0697 4.93045 26.6667 5.66683 26.6667H11.0002C11.7365 26.6667 12.3335 26.0697 12.3335 25.3333V17.3333C12.3335 16.597 11.7365 16 11.0002 16Z"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M19.0002 10.6667H13.6668C12.9304 10.6667 12.3335 11.2637 12.3335 12.0001V25.3334C12.3335 26.0698 12.9304 26.6667 13.6668 26.6667H19.0002C19.7365 26.6667 20.3335 26.0698 20.3335 25.3334V12.0001C20.3335 11.2637 19.7365 10.6667 19.0002 10.6667Z"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M27.0002 5.33325H21.6668C20.9304 5.33325 20.3335 5.93021 20.3335 6.66659V25.3333C20.3335 26.0696 20.9304 26.6666 21.6668 26.6666H27.0002C27.7365 26.6666 28.3335 26.0696 28.3335 25.3333V6.66659C28.3335 5.93021 27.7365 5.33325 27.0002 5.33325Z"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
            <path
            d="M5.66699 26.6667H24.3337"
            stroke="#BDB2DD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_639_3279">
            <rect
                width="32"
                height="32"
                fill="white"
                transform="translate(0.333496)"
            />
            </clipPath>
        </defs>
        </svg>
</template>