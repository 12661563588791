<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.858C11.9922 4.79369 11.9771 4.73034 11.955 4.669V4.606C11.9189 4.53403 11.8708 4.46787 11.8125 4.41L7.3125 0.21C7.2505 0.155551 7.17962 0.110657 7.1025 0.0769999C7.08011 0.0740321 7.05739 0.0740321 7.035 0.0769999C6.95881 0.0362189 6.87467 0.0100409 6.7875 0H2.25C1.65326 0 1.08097 0.221249 0.65901 0.615076C0.237053 1.0089 0 1.54305 0 2.1V11.9C0 12.457 0.237053 12.9911 0.65901 13.3849C1.08097 13.7788 1.65326 14 2.25 14H9.75C10.3467 14 10.919 13.7788 11.341 13.3849C11.7629 12.9911 12 12.457 12 11.9V4.9C12 4.9 12 4.9 12 4.858ZM7.5 2.387L9.4425 4.2H8.25C8.05109 4.2 7.86032 4.12625 7.71967 3.99497C7.57902 3.8637 7.5 3.68565 7.5 3.5V2.387ZM10.5 11.9C10.5 12.0857 10.421 12.2637 10.2803 12.395C10.1397 12.5263 9.94891 12.6 9.75 12.6H2.25C2.05109 12.6 1.86032 12.5263 1.71967 12.395C1.57902 12.2637 1.5 12.0857 1.5 11.9V2.1C1.5 1.91435 1.57902 1.7363 1.71967 1.60503C1.86032 1.47375 2.05109 1.4 2.25 1.4H6V3.5C6 4.05695 6.23705 4.5911 6.65901 4.98492C7.08097 5.37875 7.65326 5.6 8.25 5.6H10.5V11.9ZM7.7175 7.203L5.25 9.513L4.2825 8.603C4.14127 8.47119 3.94973 8.39714 3.75 8.39714C3.55027 8.39714 3.35873 8.47119 3.2175 8.603C3.07627 8.73481 2.99693 8.91359 2.99693 9.1C2.99693 9.28641 3.07627 9.46519 3.2175 9.597L4.7175 10.997C4.78722 11.0626 4.87017 11.1147 4.96157 11.1502C5.05296 11.1858 5.15099 11.2041 5.25 11.2041C5.34901 11.2041 5.44704 11.1858 5.53843 11.1502C5.62983 11.1147 5.71278 11.0626 5.7825 10.997L8.7825 8.197C8.92373 8.06519 9.00307 7.88641 9.00307 7.7C9.00307 7.51359 8.92373 7.33481 8.7825 7.203C8.64127 7.07119 8.44973 6.99714 8.25 6.99714C8.05027 6.99714 7.85873 7.07119 7.7175 7.203Z"
    />
  </svg>
</template>
