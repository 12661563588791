<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 6.3C6.81435 6.3 6.6363 6.37375 6.50503 6.50502C6.37375 6.6363 6.3 6.81435 6.3 7V9.8C6.3 9.98565 6.37375 10.1637 6.50503 10.295C6.6363 10.4262 6.81435 10.5 7 10.5C7.18565 10.5 7.3637 10.4262 7.49498 10.295C7.62625 10.1637 7.7 9.98565 7.7 9.8V7C7.7 6.81435 7.62625 6.6363 7.49498 6.50502C7.3637 6.37375 7.18565 6.3 7 6.3ZM7.266 3.556C7.09558 3.48599 6.90443 3.48599 6.734 3.556C6.64808 3.58932 6.56957 3.63927 6.503 3.703C6.44117 3.77104 6.39145 3.84917 6.356 3.934C6.31681 4.01708 6.29764 4.10818 6.3 4.2C6.29947 4.29212 6.31713 4.38345 6.35196 4.46873C6.3868 4.55402 6.43813 4.63159 6.503 4.697C6.57104 4.75883 6.64917 4.80855 6.734 4.844C6.84005 4.88757 6.95518 4.90442 7.06926 4.89308C7.18335 4.88173 7.29291 4.84254 7.3883 4.77895C7.48369 4.71535 7.56201 4.6293 7.61636 4.52835C7.67071 4.4274 7.69943 4.31465 7.7 4.2C7.69742 4.01466 7.62492 3.83714 7.497 3.703C7.43043 3.63927 7.35193 3.58932 7.266 3.556ZM7 0C5.61553 0 4.26215 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.00303298 5.6003 -0.13559 7.00776 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C14 6.08075 13.8189 5.17049 13.4672 4.32122C13.1154 3.47194 12.5998 2.70026 11.9497 2.05025C11.2997 1.40024 10.5281 0.884626 9.67879 0.532843C8.82951 0.18106 7.91925 0 7 0ZM7 12.6C5.89243 12.6 4.80972 12.2716 3.88881 11.6562C2.96789 11.0409 2.25013 10.1663 1.82628 9.14303C1.40243 8.11976 1.29153 6.99379 1.50761 5.90749C1.72368 4.8212 2.25703 3.82338 3.0402 3.0402C3.82338 2.25703 4.8212 1.72368 5.9075 1.5076C6.99379 1.29153 8.11976 1.40242 9.14303 1.82627C10.1663 2.25012 11.0409 2.96789 11.6562 3.88881C12.2716 4.80972 12.6 5.89242 12.6 7C12.6 8.48521 12.01 9.90959 10.9598 10.9598C9.9096 12.01 8.48521 12.6 7 12.6Z"
    />
  </svg>
</template>
