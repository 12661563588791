
import { defineComponent } from 'vue';

import IconAbout from '@/components/icons/IconAbout.vue';
import Discord from '@/components/icons/Discord.vue';
import Docs from '@/components/icons/Docs.vue';
import HelpCenter from '@/components/icons/HelpCenter.vue';
import Kyc from '@/components/icons/Kyc.vue';
import LegalAndPrivacy from '@/components/icons/LegalAndPrivacy.vue';

export default defineComponent({
  name: 'AppNavNetworkSelect',
  components: {
    IconAbout,
    Discord,
    Docs,
    HelpCenter,
    Kyc,
    LegalAndPrivacy,
  },
});
