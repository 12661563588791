import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0950ed5f")
const _hoisted_1 = { class: "flex-col" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = {
  key: 0,
  class: "mx-[3px]"
}
_popScopeId()

import { PoolToken } from '@/services/pool/types';

import BalanceTooltip from './BalanceTooltip.vue';

type Props = {
  hasBalance: boolean;
  symbol: string;
  weight: string;
  isSelected: boolean;
  token: PoolToken;
  lastToken: boolean;
  showWeight: boolean;
};


export default _defineComponent({
  props: {
    hasBalance: { type: Boolean, required: true, default: false },
    symbol: { type: String, required: true },
    weight: { type: String, required: true },
    isSelected: { type: Boolean, required: true, default: false },
    token: { type: null, required: true },
    lastToken: { type: Boolean, required: true },
    showWeight: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  hasBalance: boolean;
  symbol: string;
  weight: string;
  isSelected: boolean;
  token: PoolToken;
  lastToken: boolean;
  showWeight: boolean;
}) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(__props.symbol) + " ", 1),
      (!__props.lastToken)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, "-"))
        : _createCommentVNode("", true)
    ]),
    (__props.showWeight)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([{ isMobile: _ctx.weightMobile, isDesktop: __props.weight }, "weight text-center"])
        }, _toDisplayString(__props.weight), 3))
      : _createCommentVNode("", true)
  ]))
}
}

})