import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-24b324e5")
const _hoisted_1 = { class: "app-body" }
const _hoisted_2 = { class: "pb-16" }
_popScopeId()

import Footer from '@/components/footer/Footer.vue';
import AppNav from '@/components/navs/AppNav/AppNav.vue';
import useBackgrounds from '@/composables/useBackgrounds';
import useBreakpoints from '@/composables/useBreakpoints';


export default _defineComponent({
  setup(__props) {

const { isSunrise, isBond, isDawn, isAirdrop } = useBackgrounds();
const { isDesktop } = useBreakpoints();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-custom-background", {
      'bond-bg': _unref(isBond) && _unref(isDesktop),
      'sunrise-bg': _unref(isSunrise) && _unref(isDesktop),
      'dawn-bg': _unref(isDawn) && _unref(isDesktop),
      'airdrop-bg': _unref(isAirdrop) && _unref(isDesktop),
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(AppNav),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view, {
          key: _ctx.$route.path
        })
      ])
    ]),
    _createVNode(Footer)
  ], 2))
}
}

})